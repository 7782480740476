(function (app) {
    'use strict';

    app.controller('PaymentIFrameDialogCtrl', [
        '$scope', '$rootScope', '$timeout', '$filter', 'Config', 'Loading', 'Util', 'options', 'Dialog', 'SP_PAYMENTS',
        function($scope, $rootScope, $timeout, $filter, Config, Loading, Util, options, Dialog, SP_PAYMENTS) {
            var paymentIFrameCtrl = this,
                _iFrameId = 'payment_dialog_iframe',
                _iFrameInitiated = false,
                _iFrameLoaded = false,
                _iframeTimeOut,
                _loadingTimeOut,
                _iframeLoadTimeOut;

            var _getPaymentMethodName = $filter('paymentMethod');

            paymentIFrameCtrl.iFrameUrl = options.url;
            paymentIFrameCtrl.paymentMethodId = options.paymentMethodId;
            paymentIFrameCtrl.giftCardName = _getPaymentMethodName(paymentIFrameCtrl.paymentMethodId);
            paymentIFrameCtrl.isAddCard = options.isAddCard;
            paymentIFrameCtrl.bindIFrameLoad = bindIFrameLoad;
            paymentIFrameCtrl.creditCardDisplay = Config.retailer.settings && Config.retailer.settings.creditCardDisplay && JSON.parse(Config.retailer.settings.creditCardDisplay);
            paymentIFrameCtrl.giftCardDisplay = Config.retailer.settings && Config.retailer.settings.giftCardDisplay && JSON.parse(Config.retailer.settings.giftCardDisplay);
            paymentIFrameCtrl.titleGiftCard = titleGiftCard;
            if (options.isLoyalty && options.creditCardLoyaltyClubDriverId) {
                var loyaltyClubDriver =  Config.retailer.loyaltyClubDrivers.find(function (driver) {
                    return driver.id === options.creditCardLoyaltyClubDriverId;
                });

                if (!!loyaltyClubDriver) {
                    paymentIFrameCtrl.loyaltyClubDriver = loyaltyClubDriver;

                }
            }

            /**
             * Start loading image for iFrame and bind on load event to iFrame
             */
            function bindIFrameLoad() {
                if (_iFrameInitiated) {
                    return;
                }

                _iframeTimeOut = $timeout(function() {
                    if (_iFrameInitiated) {
                        return;
                    }

                    var iFrameElement = document.getElementById(_iFrameId);
                    if (!iFrameElement) {
                        bindIFrameLoad();
                        return;
                    }

                    _iFrameInitiated = true;
                    Loading.counter(null, 1);
                    angular.element(iFrameElement).bind('load', _onIFrameLoad);
                    _loadingTimeOut = $timeout(function() {
                        _onIFrameLoad({ target: iFrameElement }, true);
                    }, 60000);
                    Util.currentScopeListener($scope, $rootScope.$on('init_cart_iframe_redirect.finished', _setIframeLoaded));
                    _onIFrameLoad({ target: iFrameElement });
                }, 100);
            }

            /**
             * Stop loading image for iFrame and unbind on load event
             * @private
             *
             * @param {Event} event
             * @param {boolean} [isTimeout]
             */
            function _onIFrameLoad(event, isTimeout) {
                try {
                    if (isTimeout) {
                        throw 'Loading timeout';
                    }

                    var iframe = event.target;
                    if (iframe.contentDocument) {
                        var preTags = iframe.contentDocument.querySelectorAll('pre');
                        preTags.forEach(function (preTag) {
                            if (preTag.textContent && preTag.textContent.includes('status')) {
                                var data = JSON.parse(preTag.textContent);
                                if (data.status === 500 && paymentIFrameCtrl.paymentMethodId === SP_PAYMENTS.PAYMENT_METHODS.IDS.EBT) {
                                    Dialog.hide();
                                    Util.showPaymentErrorDialog(data);
                                    return;
                                }
                            }
                        });
                    }

                    var toTryAccessIFrame = event.target.contentWindow.location.href;

                    if (!toTryAccessIFrame || toTryAccessIFrame.indexOf('http') !== 0) {
                        return;
                    }

                    if (toTryAccessIFrame.indexOf(window.location.origin) === -1) {
                        throw 'Set as loaded';
                    }
                } catch(e) {
                    _setIframeLoaded();
                }
            }

            function _setIframeLoaded() {
                if (_iFrameLoaded) {
                    return;
                }

                if (_loadingTimeOut) {
                    $timeout.cancel(_loadingTimeOut);
                }

                _iframeLoadTimeOut = $timeout(function() {
                    _iFrameLoaded = true;
                    Loading.counter(null, -1);

                    var iFrameElement = document.getElementById(_iFrameId);
                    if (iFrameElement) {
                        angular.element(iFrameElement).unbind('load', _onIFrameLoad);
                    }
                }, 0);
            }

            Util.currentScopeListener($scope, function() {
                // cancel loading when dialog closes
                if (_iFrameInitiated && !_iFrameLoaded) {
                    Loading.counter(null, -1);
                }

                // cancel timeouts
                _iframeTimeOut && $timeout.cancel(_iframeTimeOut);
                _loadingTimeOut && $timeout.cancel(_loadingTimeOut);
                _iframeLoadTimeOut && $timeout.cancel(_iframeLoadTimeOut);
            });


            function titleGiftCard(text) {
                return (text || '').replaceAll(/\{Gift card name\}/g, Config.retailer.settings.giftCardNamesSettings[paymentIFrameCtrl.paymentMethodId][$rootScope.config.language.id]);
            }
        }

    ]);
})(app);
