(function (angular, app) {
    'use strict';

    app.directive('spQuantity', [function () {
        return {
            restrict: 'E',
            scope: {
                cartLine: '=',
                hideCart: '@',
                hideWeight: '@',
                estText: '@',
                design: '@',
                isPack: '=?',
                readOnly: '<?',
                minQuantity: '=?',
                spAriaLive: '=?',
                /**
                 * returns true if not to activate 'cart.plusQuantity' function
                 */
                onPlus: '&?',
                /**
                 * returns true if not to activate 'cart.minusQuantity' function
                 */
                onMinus: '&?',
                /**
                 * returns true if not to activate 'cart.quantityChanged' function
                 */
                onChange: '&?',
                plusAlt: '@?',
                minusAlt: '@?',
                isRedesign: '=?',
                source: '@'
            },
            replace: true,
            controller: ['$scope', '$rootScope', 'Cart', 'SpCartService', 'Util', 'spUnits', 'spUnitsConfig', function ($scope, $rootScope, cart, spCartService, util, spUnits, spUnitsConfig) {
                var _interval,
                    _intervalInUnit,
                    ACCURACY = 100;

                _init();
                
                $scope.grayBtnsDesigns = {white: true};

                $scope.quantityChanged = quantityChanged;
                $scope.selectOnFocus = selectOnFocus;
                $scope.onBlur = onBlur;
                $scope.onPlusClick = onPlusClick;
                $scope.onMinusClick = onMinusClick;

                var quantityChangedTimer;
                var quantityChangeTimeout = 200;

                $scope.oldQuantity = $scope.quantity;
                
                function onPlusClick() {
                    if ($scope.onPlus && $scope.onPlus()) return;
                    var quantity = Math.round(($scope.quantity + _intervalInUnit * ($scope.isPack ? $scope.cartLine.product.branch.packQuantity : 1)) * ACCURACY) / ACCURACY;
                    var cartLineId = $scope.cartLine.id;
                    var productId = $scope.cartLine.product.productId;
                    var weightableQuantity;
                    if ((util.isUnitsWeighable($scope.cartLine.product) && util.productSoldBy($scope.cartLine.product, $scope.cartLine.soldBy) === $rootScope.PRODUCT_DISPLAY.WEIGHT.name) || util.isWeightQuantity($scope.cartLine.product)) {
                        weightableQuantity  = $scope.unit ? spUnits.convert($scope.quantity, $scope.unit, {
                            to: $rootScope.defaultWeightUnit.unit
                        }).value : $scope.quantity;
                    }
                    var result = cart.checkHeavyLimitBeforeAddQuantity(cartLineId, productId, weightableQuantity || quantity);
                    if (result === false) {
                        if (cart.lines && Object.keys(cart.lines).length === 0 &&
                            $rootScope.config.retailer.settings.includeDeliveryFeeInCart === 'true') {
                            cart.addDeliveryFeeLineIfNeeded();
                        }
                        $scope.quantity = quantity;
                        quantityChanged('increase');
                    }
                }

                function onMinusClick() {
                    if ($scope.onMinus && $scope.onMinus()) return;
                    $scope.quantity = Math.round(($scope.quantity - _intervalInUnit * ($scope.isPack ? $scope.cartLine.product.branch.packQuantity : 1)) * ACCURACY) / ACCURACY;
                    quantityChanged('decrease');
                }

                function quantityChanged(direction) {
                    if($scope.quantity < 0){
                        $scope.quantity = 0;
                    }
                    if (direction === undefined) {
                        direction = $scope.oldQuantity - $scope.quantity >= 0 ?  'decrease' : 'increase';
                    }
                    if(quantityChangedTimer){
                        clearTimeout(quantityChangedTimer);
                    }
                    //added timeout to prevent multiple calls to server during the client types the amount
                    quantityChangedTimer = setTimeout(function() {callQuantityChanged(direction)}, quantityChangeTimeout);
                }

                function callQuantityChanged(direction) {
                    if (!isNaN($scope.minQuantity) && $scope.quantity < $scope.minQuantity) {
                        $scope.quantity = $scope.minQuantity;
                    }
                    var oldQuantity = $scope.cartLine.quantity;
                    var quantity;
                    var intervalInUnit = _intervalInUnit * ($scope.isPack ? $scope.cartLine.product.branch.packQuantity : 1);

                    if ((util.isUnitsWeighable($scope.cartLine.product) && util.productSoldBy($scope.cartLine.product, $scope.cartLine.soldBy) === $rootScope.PRODUCT_DISPLAY.UNIT.name) || (!util.productSoldBy($scope.cartLine.product, $scope.cartLine.soldBy) && !util.isWeightQuantity($scope.cartLine.product))) {
                        // check if new unit quantity have decimal value
                        if(($scope.quantity % 1) > 0) {
                            $scope.quantity = oldQuantity;
                            return;
                        }

                        $scope.quantity = parseInt($scope.quantity);
                        // must be done on scope to take effect in view
                        quantity = $scope.quantity;
                    } else {
                        // check if new weight quantity have invalid decimal value
                        if(Math.round($scope.quantity * ACCURACY) % Math.round(intervalInUnit * ACCURACY) != 0) {
                            $scope.quantity = oldQuantity;
                            return;
                        }

                        quantity = $scope.unit ? spUnits.convert($scope.quantity, $scope.unit, {
                            to: $rootScope.defaultWeightUnit.unit
                        }).value : $scope.quantity;
                    }
                    var cartLineId = $scope.cartLine.id;
                    var productId = $scope.cartLine.product.productId;

                    if (direction === 'decrease') {
                        $scope.cartLine.quantity = quantity;
                        $rootScope.$emit('cart.lines.quantityChanged.click', {lines: [$scope.cartLine], direction: direction}, {source: $scope.source});

                        _callOnChangeAndCart();
                    } else {
                        var result = cart.checkHeavyLimitBeforeAddQuantity(cartLineId, productId, quantity);
                        if (result === false) {
                            if (cart.lines && Object.keys(cart.lines).length === 0 &&
                                $rootScope.config.retailer.settings.includeDeliveryFeeInCart === 'true') {
                                cart.addDeliveryFeeLineIfNeeded();
                            }
                            $scope.cartLine.quantity = quantity;
                            $rootScope.$emit('cart.lines.quantityChanged.click', {lines: [$scope.cartLine], direction: direction}, {source: $scope.source});

                            _callOnChangeAndCart();
                        } else {
                            $scope.quantity = oldQuantity;
                            $scope.cartLine.quantity = oldQuantity;
                        }
                    }

                    if ($scope.orginalUnit && $scope.orginalUnit.type === 'mass' && $scope.orginalUnit.size === 1) {
                        var resizedUnit = _resizeUnit();
                        if(resizedUnit.size !== $scope.unit.size){
                            _init();
                        }
                    }
                }

                function selectOnFocus($event) {
                    ($event.target || $event.currentTarget || $event.srcElement).select();
                }

                function onBlur() {
                    if ($scope.quantity) return;

                    _callOnChangeAndCart();
                }

                function _resizeUnit(){
                    var units = spUnitsConfig.getGroup().units.mass.units;
                    var minUnitSize = 1;
                    var kgUnitSize = 1000;
                    
                    for (var i = 0; i < units.length; i++) {
                        if ($scope.cartLine.quantity * kgUnitSize / units[i].size < minUnitSize) {
                           return units[i - 1];
                        }
                    }

                    return units[units.length - 1];
                }

                function _init() {
                    $scope.unit = !$scope.cartLine.isCase ? util.getProductUnit($scope.cartLine.product, $scope.cartLine.soldBy) : null;
                    $scope.orginalUnit = $scope.orginalUnit || $scope.unit;
                    if ($scope.orginalUnit && $scope.orginalUnit.type === 'mass' && $scope.orginalUnit.size === 1) {
                        var resizeUnit = _resizeUnit();
                        $scope.unit = resizeUnit; 
                    }

                    _interval = spCartService.quantityInterval($scope.cartLine,  $scope.cartLine.soldBy);
                    _intervalInUnit = parseFloat(($scope.unit ? spUnits.convert(_interval, $rootScope.defaultWeightUnit.unit, {
                        to: $scope.unit
                    }).value : _interval).toFixed(2));
                    _setQuantity();
                    if ($scope.isRedesign) {
                        _reDesignButtons();
                    }
                }

                function _callOnChangeAndCart() {
                    if ($scope.onChange && $scope.onChange()) return;
                    var currentQuantitiy = $scope.cartLine.quantity;
                    cart.quantityChanged($scope.cartLine, null, $scope.cartLine.soldBy);
                    if (currentQuantitiy != $scope.cartLine.quantity) {
                        $scope.quantity = $scope.cartLine.quantity;
                    }
                }

                function _setQuantity() {
                    //set quantity from cart line
                    var cartLine = $scope.cartLine;
                    if (!isNaN(cartLine.quantity)) {
                        if (util.isWeightQuantity(cartLine.product) || util.productSoldBy(cartLine.product, $scope.cartLine.soldBy) === $rootScope.PRODUCT_DISPLAY.WEIGHT.name) {
                            $scope.quantity = spUnits.convert(cartLine.quantity, $rootScope.defaultWeightUnit.unit, {
                                to: $scope.unit
                            }).value;
                        } else {
                            $scope.quantity = parseInt(cartLine.quantity);
                        }
                    }

                    if ($scope.quantity) {
                        $scope.quantity = parseFloat($scope.quantity.toFixed(2));
                    } else {
                        /*//only if on change allows to touch cart, remove the line from the cart
                        if (!$scope.onChange || !$scope.onChange()) {
                            cart.removeLine($scope.cartLine);
                        }*/

                        $scope.quantity = $scope.minQuantity || 0;
                    }
                }

                function _reDesignButtons() {
                    var mainColor = (window.sp && window.sp.defaultThemeConfig && window.sp.defaultThemeConfig.mainColor) || '#207d3f',
                      backgroundColor = $scope.isRedesign ? '#000000' : mainColor,
                      elem = document.getElementById("plus");

                    if (elem) {
                        elem.style.backgroundColor = backgroundColor;
                        elem = document.getElementById("minus");
                        elem.style.backgroundColor = backgroundColor;
                    }
                }

                util.currentScopeListener($scope, $scope.$watch('cartLine', _init));
                util.currentScopeListener($scope, $scope.$watch('cartLine.soldBy', function (newSoldBy, oldSoldBy) {
                    newSoldBy = newSoldBy || null;
                    oldSoldBy = oldSoldBy || null;

                    if (newSoldBy === oldSoldBy) return;
                    _init();
                    _callOnChangeAndCart();
                }));
                util.currentScopeListener($scope, $scope.$watch('cartLine.quantity', _setQuantity));
            }],
            templateUrl: 'template/directives/sp-quantity/index.html'
        };
    }]);
})(angular, app);
