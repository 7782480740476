(function (angular, app) {
    'use strict';
    app.directive('spKikarInputDynamicDimension', ['$timeout', '$rootScope', function ($timeout, $rootScope) {
        return {
            restrict: 'A',
            link: function ($scope, $element, $attrs) {
                var _TYPE = {
                    LABEL: 'label',
                    ERROR: 'error'
                }

                _init();

                function _init() {
                    // Set up a MutationObserver to detect changes in the label
                    var _parent = $element.parent()[0];
                    if (_parent) {
                        var _label = _parent.querySelector('label');
                        if (_label) {
                            var observer = new MutationObserver(function () {
                                updateDimension(_label, _TYPE.LABEL);
                            });

                            _observe(observer, _label);
                        }
                    }
                }


                function _observe(_observer, _element) {
                    // Observe changes to child nodes and character data
                    _observer.observe(_element, {
                        childList: true, // For changes in text nodes
                        characterData: true, // For direct text changes
                        subtree: true, // To observe text within child elements
                        attributes: true, // Observe attribute changes
                        attributeFilter: ['style', 'class'], // Only observe the style attribute
                    });

                    // Cleanup observer on scope destroy
                    $scope.$on('$destroy', function () {
                        _observer.disconnect();
                    });
                }

                // Function to update padding
                function updateDimension(_element, _type, _parent) {
                    switch (_type) {
                        case _TYPE.LABEL: {
                            var _eHeight = _element.offsetHeight;
                            $element.css('padding-top', _eHeight + 'px');
                            break;
                        }
                        case _TYPE.ERROR: {
                            var _eHeight = _element.offsetHeight;
                            _parent.css('margin-bottom', _eHeight + 'px');
                            break;
                        }
                    }
                }

                $rootScope.$on('spInlineError.element.added', function (event, data) {
                    if ($attrs.id && data.attrId && data.attrId === $attrs.id) {
                        var _error = document.getElementById(data.elementId);
                        if (_error) {
                            var observer = new MutationObserver(function () {
                                var _parent = $element.parent();
                                updateDimension(_error, _TYPE.ERROR, _parent);
                            });

                            _observe(observer, _error);
                        }
                    }
                });
            }
        };
    }]);
})(angular, app);