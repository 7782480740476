(function (angular, app) {
    'use strict';

    app
        .config(['$stateProvider', 'ROUTE_ACCESS', function ($stateProvider, ROUTE_ACCESS) {
            addState('app.update-order.finish');
            addState('app.user-confirmation.finish');

            function addState(stateName) {
                $stateProvider.state(stateName, {
                    url: '/finish',
                    data: {
                        routeAccess: ROUTE_ACCESS.LOGIN,
                        name: 'finish',
                        layout: {
                            menu: false,
                            search: false,
                            sidenav: false
                        }
                    },
                    params: {
                        order: null
                    },
                    metaTags: {
                        title: ['$filter', function ($filter) {
                            return $filter('translate')('Finish order');
                        }]
                    },
                    views: {
                        updateOrderTab: {
                            templateUrl: 'template/views/update-order/finish/index.html',
                            controller: 'UpdateOrderFinishCtrl as finishCtrl'
                        }
                    }
                });
            }
        }])
        .controller('UpdateOrderFinishCtrl', [
            '$scope', '$rootScope', '$timeout', '$location', '$state', 'Config', 'Util', 'User', 'Cart', 'SP_SERVICES', 'DataLayer',
            function ($scope, $rootScope, $timeout, $location, $state, Config, Util, User, Cart, SP_SERVICES, DataLayer) {
                var finishCtrl = this,
                    updateOrderCtrl = $scope.updateOrderCtrl,
                    _timer;
                updateOrderCtrl.withoutContent = false;

                updateOrderCtrl.isFinishTab = true;
                finishCtrl.retailerData = Config.retailer;
                finishCtrl.order = $state.params.order || {};
                var creditCardColumns = [];
                var generatedColumns = [];

                if(finishCtrl.order && finishCtrl.order.id) {
                    var serviceFee = 0;
                    if(finishCtrl.order.lines && finishCtrl.order.lines.length) {
                        var findServiceFee = finishCtrl.order.lines.find(function (line) {
                            return line.type === SP_SERVICES.CART_LINE_TYPES.SERVICE_FEE;
                        })
                        serviceFee = findServiceFee && findServiceFee.totalPrice;
                    }

                    getOrderDeliveryTime();
                    generatedColumns.push(
                        _columnObj('Items', _countCartLinesUnitsItems()),
                        _columnObj('Sub total', getOrderSubtotal(), true, [], false, true),
                        _columnObj('Delivery fee', +finishCtrl.order.deliveryFee.toFixed(2), +finishCtrl.order.deliveryFee > 0, [], false, true),
                        _columnObj('Service fee', serviceFee ? serviceFee.toFixed(2) : 0, (serviceFee || 0) > 0, [], false, true),
                        _columnObj('Tax', +finishCtrl.order.totalTax.toFixed(2), +finishCtrl.order.totalTax > 0, [], false, true),
                        _columnObj('Total (estimated)', +finishCtrl.order.totalAmount.toFixed(2), +finishCtrl.order.totalAmount > 0, ['bold', 'colored'])
                    );

                    if(finishCtrl.order.paymentData && finishCtrl.order.paymentData.mainPayment && finishCtrl.order.paymentData.mainPayment.lastFourDigits) {
                        creditCardColumns.push(_columnObj('Credit Card', '****' +  finishCtrl.order.paymentData.mainPayment.lastFourDigits));
                    }
                }

                finishCtrl.columns = [generatedColumns, creditCardColumns]

                function _columnObj(text, value, hasCurrency, classes, hideColon, withoutValue) {
                    return {
                        text: text,
                        hasCurrency: hasCurrency,
                        value: value,
                        classes: classes,
                        hideColon: hideColon,
                        withoutValue: withoutValue
                    }
                }

                function _countCartLinesUnitsItems() {
                    return finishCtrl.order && finishCtrl.order.lines ? finishCtrl.order.lines.reduce(function(sum, line) {
                        if (line.type === SP_SERVICES.CART_LINE_TYPES.PRODUCT) {
                            sum += line.actualQuantity;
                        }
                        return sum;
                    }, 0) : 0;
                }

                function getOrderSubtotal() {
                    var subtotal = finishCtrl.order.totalAmount - (finishCtrl.order.deliveryFee + finishCtrl.order.totalTax);
                    if(subtotal > 0) {
                        return subtotal.toFixed(2);
                    }
                    return 0;
                }

                function getOrderDeliveryTime () {
                    var weekday = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];
                    var dayName = weekday[new Date(finishCtrl.order.shippingTimeFrom).getDay()];
                    var date = new Date(finishCtrl.order.shippingTimeFrom).getDate();
                    var month = new Date(finishCtrl.order.shippingTimeFrom).getMonth() + 1;
                    var timeFrom = new Date(new Date(finishCtrl.order.shippingTimeFrom).getTime() + (new Date(finishCtrl.order.shippingTimeFrom).getTimezoneOffset() * 60 * 1000));
                    var timeTo = new Date(new Date(finishCtrl.order.shippingTimeTo).getTime() + (new Date(finishCtrl.order.shippingTimeTo).getTimezoneOffset() * 60 * 1000));
                    var formattedTimeFrom = timeFrom.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: false });
                    var formattedTimeTo = timeTo.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: false });

                    finishCtrl.order.formattedDeliveryDate = dayName + ' ' + date + '/' + month;
                    finishCtrl.order.formattedDeliveryTime = formattedTimeFrom + ' - ' + formattedTimeTo;
                }

                if (updateOrderCtrl.isCreditCardLoyalty) {
                    var loyaltyClubDriver = Config.retailer.loyaltyClubDrivers.find(function (loyaltyClubDriver) {
                        return loyaltyClubDriver.id === updateOrderCtrl.creditCardLoyaltyClubDriverId;
                    });

                    if (loyaltyClubDriver.clientConfig.text.postCheckout) {
                        Util.showRetailerMessageDialog(Config.retailer.loyaltyClubDriver.clientConfig.text.postCheckout);
                    }
                }

                var afterOrderFinishText = Util.getMessagesAlertText(Config.retailer.settings.afterOrderFinishedText);
                if (afterOrderFinishText) {
                    Util.showRetailerMessageDialog(afterOrderFinishText);
                }

                User.getData().then(function (data) {
                    finishCtrl.userData = data;
                });

                finishCtrl.continue = function () {
                    $state.go('app.home');
                };

                finishCtrl.editOrder = function () {
                    DataLayer.push(DataLayer.EVENTS.SELECT_CONTENT, {data: {category: 'Button', action: 'Click', label: 'Checkout Confirmation - Edit Order'}});

                    return Util.showEditOrderWarning(updateOrderCtrl.oid || updateOrderCtrl.order.id).then(function (isEdit) {
                        if (isEdit) {
                            Cart.close();
                            $state.go('app.home');
                        }
                    });
                };

                _timer = $timeout(function () {
                    angular.element(document.querySelector('.cart-icon > .empty-cart > .v-icon')).addClass('shown');
                }, 500);

                $scope.$on('$destroy', function () {
                    _timer && $timeout.cancel(_timer);
                });
            }]);
})(angular, app);
