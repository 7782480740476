(function (angular, app) {
    'use strict';

    app.directive('spItems', [
        '$rootScope', '$stateParams', '$timeout', 'LocalStorage', 'Util', 'Items', 'SP_ITEMS_DESIGNS', 'DataLayer',
        function ($rootScope, $stateParams, $timeout, LocalStorageService, Util, Items, SP_ITEMS_DESIGNS, DataLayer) {
            return {
                restrict: 'E',
                scope: {
                    itemClass: '@',
                    subitemClass: '@',
                    templateUrl: '@',
                    items: '=',
                    onClick: '&?',
                    filters: '=',
                    sorters: '=',
                    bannerTitle: '@',
                    categoryDescription: '@',
                    img: '@',
                    noResultsMessage: '@',
                    total: '=',
                    design: '=?',
                    groupBy: '=?',
                    backBtn: '=?',
                    imageBannerPresent: '=?',
                    size: '@',
                    showItemDescription: '<',
                    categoryType: '<',
                    userPoints: '=?',
                    spTabindex: '@?',
                    spRole: '@?',
                    threshold: '=?',
                    onSearchProducts: '&?',
                    source: '@'
                },
                templateUrl: 'template/directives/sp-items/index.html',
                link: function ($scope, element) {
                    var _hasFiltersOptions = false,
                        _groupsMap = {},
                        _thresholdToSet = 300;
                    $scope.cantFindItem = Items.cantFindItem;
                    $scope.design = $scope.design || SP_ITEMS_DESIGNS.REGULAR;
                    $scope.itemWidth = Items.width;
                    $scope.filtersShown = LocalStorageService.getItem('filtersState') === '0';
                    $scope.filtersNotFinished = 0;
                    $scope.groups = [];
                    $scope.initialized = !!$scope.items && !!$scope.items.length;
                    $scope.stateParams = $stateParams;
                    $scope.hideGroups = {};
                    $scope.total = null;
                    $scope.userBalancePoints = "";
                    $scope.searchProductsModel = null;
                    Items.items = $scope.items;
                    if ($scope.threshold !== undefined && typeof $scope.threshold === 'number') {
                        _thresholdToSet = $scope.threshold;
                    }
                    $scope.threshold = _thresholdToSet;

                    $scope.isInProcess = Items.isChunkInProcess;

                    $scope.onChangeFilter = onChangeFilter;
                    $scope.clearAllFilter = clearAllFilter;
                    $scope.clearAllFilters = clearAllFilters;
                    $scope.clickOnItem = clickOnItem;
                    $scope.searchProductsChanged = searchProductsChanged;
                    $scope.toggleFilters = toggleFilters;
                    $scope.addCouponToCart = addCouponToCart;
                    $scope.searchCoupon = searchCoupon;
                    $scope.searchCouponChanged = searchCouponChanged;
                    $scope.back = back;
                    $scope.calculateItemWidth = calculateItemWidth;

                    angular.forEach($scope.sorters, function (obj) {
                        if (!obj.onChange || !angular.isFunction(obj.onChange)) return;

                        $scope.$watch(function () {
                            return obj.value;
                        }, function (newVal, oldVal) {
                            if (newVal === oldVal) return;

                            obj.onChange();
                        }, true);
                    });

                    angular.forEach($scope.filters, function (filter) {
                        //initialize checked boolean on value items
                        _resetValues(filter);
                        filter.subscribeResetValues(_resetValues);

                        if (!filter.notFinished) {
                            return _hasFiltersOptions = _hasFiltersOptions || !!filter.options.items.length;
                        }

                        $scope.filtersNotFinished += 1;
                        var listener = $scope.$watch(function () {
                            return filter.notFinished;
                        }, function (val) {
                            if (val) return;

                            $scope.filtersNotFinished -= 1;
                            listener();

                            _hasFiltersOptions = _hasFiltersOptions || !!filter.options.items.length;
                            _setHasTools();
                        });
                    });

                    _setHasTools();
                    toggleFilters();
                    _setBrandSearchTerm();

                    function _setBrandSearchTerm() {
                        if($stateParams.filters && $stateParams.filters.queryInBrand) {
                            $scope.searchProductsModel = $stateParams.filters.queryInBrand;
                        }
                    }

                    function _resetValues(filter) {
                        angular.forEach(filter.options.items, function(valueItem) {
                            valueItem.checked = false;
                        });

                        if (!filter.value) {
                            return;
                        }

                        angular.forEach(filter.value, function(valueItem) {
                            valueItem.checked = true;
                        });

                        _setHasSelectedFilter();
                        _setBoostProductTags(filter);
                    }

                    function _setHasSelectedFilter() {
                        $scope.hasSelectedFilters = !!$scope.filters.find(function(filter) {
                            return (filter.options && filter.options.items || []).find(function(item) {
                                return item.checked;
                            });
                        });
                        $scope.hideInnerFilterField = !!($stateParams.query && $stateParams.query.length);
                    }

                    function _setBoostProductTags(filter) {
                        if (!filter.isProductTags) {
                            return;
                        }

                        $scope.boostProductTags = [];
                        angular.forEach(filter.value, function(item) {
                            $scope.boostProductTags.push(item.value);
                        });
                    }

                    function onChangeFilter(filter, currentItem) {
                        DataLayer.push(DataLayer.EVENTS.SELECT_CONTENT, {data: {category: 'Button', action: 'Click', label: filter.title + ' Filter in ' + ($rootScope.MetaTags.productDescription || $rootScope.MetaTags.description) + ' Category'}});

                        var oldValue = angular.filterCollection(filter.options.items, function (item) {
                            if (currentItem === item) {
                                return !item.checked;
                            } else {
                                return item.checked;
                            }
                        });
                        var newValue = angular.filterCollection(oldValue, function (item) {
                            return currentItem !== item;
                        });
                        if (currentItem.checked) {
                            newValue.push(currentItem);
                        }
                        filter.value = newValue;
                        _setHasSelectedFilter();
                        _setBoostProductTags(filter);
                        filter.onChange(newValue, oldValue);
                    }

                    function clearAllFilter(filter) {
                        var oldValue = angular.filterCollection(filter.options.items, function (item) {
                            return item.checked;
                        });
                        angular.forEach(filter.options.items, function (item) {
                            item.checked = false;
                        });
                        filter.value = [];
                        _setHasSelectedFilter();
                        _setBoostProductTags(filter);
                        filter.onChange(filter.value, oldValue);
                    }

                    function clearAllFilters() {
                        angular.forEach($scope.filters, function(filter) {
                            clearAllFilter(filter);
                        });
                    }

                    function clickOnItem(item, index) {
                        if (!$scope.onClick) return;

                        $scope.onClick({$item: item, $index: index});
                    }

                    function searchProductsChanged(model) {
                        if (!$scope.onSearchProducts) return;

                        $scope.onSearchProducts({$model: model});
                    }

                    function toggleFilters() {
                        if (!$scope.hasTools) return;

                        $scope.filtersShown = !$scope.filtersShown;

                        var event = {
                            category: 'Button',
                            action: 'Click'
                        };

                        if ($scope.hasFilters) {
                            element.addClass('has-filters');
                        } else {
                            element.removeClass('has-filters');
                        }

                        if ($scope.filtersShown) {
                            LocalStorageService.setItem('filtersState', '1');
                            element.addClass('tools-shown');
                            event.label = 'Show Filters Pane';
                        } else {
                            LocalStorageService.setItem('filtersState', '0');
                            element.removeClass('tools-shown');
                            event.label = 'Hide Filters Pane';
                        }

                        if (window.event) {
                            DataLayer.push(DataLayer.EVENTS.SELECT_CONTENT, {data: event});
                        }

                        $timeout(function () {
                            Items.setItemWidth();
                        }, 200);
                    }

                    function addCouponToCart(couponCode, source) {
                        Util.addCouponToCart(couponCode, source).then(function() {
                            $rootScope.couponCode = "";
                        });
                    }

                    function searchCoupon(couponFilter, filters) {
                        $rootScope.couponsData = null;
                        $rootScope.couponFilter = couponFilter;
                        $rootScope.couponSearch = true;

                        filters[0].options.couponFilter = couponFilter;
                        filters[0].onChange([], filters[0].options.items, $rootScope.couponFilter);
                    }

                    function searchCouponChanged(couponFilter, filters) {
                        if(!$rootScope.couponSearch) { // nothing searched yet - nothing to clear
                            return;
                        }
                        if(couponFilter) { // if text is filled - user need to press Search button
                            return;
                        }
                        // In case the search field was cleared we should search for all coupons
                        $rootScope.couponFilter = '';
                        searchCoupon(couponFilter, filters);
                        $rootScope.couponSearch = false; // after we got all coupons we reset this flag
                    }


                    function back() {
                        history.go(-1);
                    }

                    function _setHasTools() {
                        $scope.hasFilters = !!($scope.filters && $scope.filters.length);
                        $scope.hasTools = !!($scope.sorters && $scope.sorters.length) || $scope.hasFilters;
                        if (!$scope.filtersNotFinished) {
                            $scope.hasFilters = _hasFiltersOptions;
                            $scope.hasTools = $scope.hasTools && _hasFiltersOptions;
                        }
                    }

                    function _groupChunk(chunk) {
                        if (!$scope.groupBy || !$scope.groupBy.by) return;

                        angular.forEach(chunk, function (item) {
                            var byVal = $scope.$eval($scope.groupBy.by, item),
                                group = _groupsMap[byVal];
                            if (!group) {
                                group = {
                                    value: $scope.$eval($scope.groupBy.value, item),
                                    columns: [],
                                    items: []
                                };
                                for (var i = 0; i < 2; i++) {
                                    group.columns.push({count: 0, items: []});
                                }
                                _groupsMap[byVal] = group;
                                $scope.groups.push(group);
                            }
                            group.isOneColumn = group.isOneColumn || $scope.$eval(($scope.groupBy.isOneColumn || 'false').toString(), item);
                            group.items.push(item);
                            var smallestColumn = {};
                            angular.forEach(group.columns, function (column) {
                                if (smallestColumn && column.count >= smallestColumn.count) return;
                                smallestColumn = column;
                            });
                            smallestColumn.items.push(item);
                            smallestColumn.count += $scope.$eval($scope.groupBy.itemHeightRatio || '1', item);
                        });
                    }

                    function calculateItemWidth(item){
                        if($scope.design == $rootScope.SP_ITEMS_DESIGNS.MENU) {
                            return '';
                        }

                        var baseWidth = $scope.itemWidth.itemCurrent;
                        var shouldBumpVideo = item.video && $scope.categoryType !== $rootScope.CATEGORIES_TYPES.LARGE_ITEMS
                        var shouldBumpElememt = item.isBumper && (item.banner || shouldBumpVideo);

                        if(!shouldBumpElememt) {
                            return baseWidth;
                        }
                        
                        if(item.second) {
                            return baseWidth * 2;
                        } else {
                            return 0;
                        }                           
                    }

                    Util.currentScopeListener($scope, $rootScope.$on('items.added', function (event, data) {
                        $scope.initialized = true;
                        _groupChunk(data.chunk.products || data.chunk.coupons);
                    }));

                    Util.currentScopeListener($scope, $rootScope.$on('items.reset', function () {
                        _groupsMap = {};
                        $scope.groups.splice(0);
                    }));
                }
            };
        }])
        // TODO: move to prutah template
        .controller('SpItemsFixedBannerPrutahTemplateCtrl', ['$scope', '$rootScope', 'Util', function ($scope, $rootScope, Util) {
            var element = angular.element(document.querySelector('sp-items')),
                _bannerDetails = {
                    element: angular.element(element[0].querySelector('.fixed-banner-wrapper > .fixed-banner')),
                    toolsWrapper: angular.element(element[0].querySelector('.tools-wrapper'))
                };

            var ONE_LINE_CLASS = 'banner-one-line';
            Util.currentScopeListener($scope, $rootScope.$on('document.scroll', function (data, event) {
                if (!_bannerDetails.body) {
                    _bannerDetails.body = _bannerDetails.element[0].querySelector('.banner-body');
                    _bannerDetails.bodyMainRow = _bannerDetails.body.querySelector('.main-row');
                    _bannerDetails.tools = angular.element(_bannerDetails.toolsWrapper[0].querySelector('.tools'));
                }
                var isShort = _bannerDetails.element.hasClass(ONE_LINE_CLASS);
                if (!_bannerDetails.height) {
                    _bannerDetails.height = {
                        banner: _bannerDetails.element.prop('clientHeight')
                    };
                }
                if (!_bannerDetails.height.body && !isShort) {
                    _bannerDetails.height.body = _bannerDetails.body.clientHeight;
                }

                var currentScrollTop = Util.getDocumentScrolledElement(event).scrollTop;
                if (!_bannerDetails.prevScrollTop || currentScrollTop < _bannerDetails.height.banner || _bannerDetails.prevScrollTop < _bannerDetails.height.banner) {
                    var nextHeight = _bannerDetails.height.banner - currentScrollTop;
                    if (nextHeight < _bannerDetails.height.body) {
                        element.addClass(ONE_LINE_CLASS);
                        if (!_bannerDetails.height.shortBody) {
                            _bannerDetails.height.shortBody = _bannerDetails.body.clientHeight;
                        }
                    } else {
                        element.removeClass(ONE_LINE_CLASS);
                    }
                    if (nextHeight < _bannerDetails.height.shortBody) {
                        nextHeight = _bannerDetails.height.shortBody;
                    }

                    _bannerDetails.element.css('height', nextHeight + 'px');
                    _bannerDetails.tools.css('padding-top', nextHeight + 'px');
                }
                _bannerDetails.prevScrollTop = currentScrollTop;
            }));
        }])

    // TODO: move to kikar template
    .controller('SpItemsFixedBannerKikarTemplateCtrl', ['$scope', '$rootScope', 'Util', function ($scope, $rootScope, Util) {

        $scope.couponCode = "";

        var element = angular.element(document.querySelector('sp-items')),
            _bannerDetails = {
                element: angular.element(angular.element(element.children()[0]))
            };

        var ONE_LINE_CLASS = 'banner-one-line';
        Util.currentScopeListener($scope, $rootScope.$on('document.scroll', function (data, event) {
            if (!_bannerDetails.titleElement) {
                _bannerDetails.titleElement = _bannerDetails.element[0].querySelector(':scope > .title-and-side-bar > .title');
            }
            if (!_bannerDetails.height) {
                _bannerDetails.height = _bannerDetails.titleElement.clientHeight;
            }

            var currentScrollTop = Util.getDocumentScrolledElement(event).scrollTop;
            if (!_bannerDetails.prevScrollTop || currentScrollTop < _bannerDetails.height || _bannerDetails.prevScrollTop < _bannerDetails.height) {
                if (currentScrollTop === 0) {
                    _bannerDetails.element.removeClass(ONE_LINE_CLASS);
                } else if (currentScrollTop > 10) { // constant threshold
                    _bannerDetails.element.addClass(ONE_LINE_CLASS);
                }
            }
            _bannerDetails.prevScrollTop = currentScrollTop;
        }));
    }]);
})(angular, app);
