(function (angular, app) {
    'use strict';

    var LOYALTY_URL = '/retailers/:rid/users/:uid/loyalty';
    app.config([

        'SpDialogUrlManagerProvider', 'SP_URL_DIALOG_QUERY_PARAMS', 'URL_DIALOG_DATA_QUERY_PARAMS',
        function (SpDialogUrlManagerProvider, SP_URL_DIALOG_QUERY_PARAMS, URL_DIALOG_DATA_QUERY_PARAMS) {
            SpDialogUrlManagerProvider.dialog({
                queryParams: SP_URL_DIALOG_QUERY_PARAMS.LOYALTY,
                dataQueryParams: URL_DIALOG_DATA_QUERY_PARAMS.LOYALTY,
                canShow: ['User', 'Util', '$timeout', '$rootScope', 'paramValue', 'DataLayer', function (User, Util, $timeout, $rootScope, paramValue, DataLayer) {
                    if (!User.session.userId) {
                        $timeout(function () {
                            DataLayer.push(DataLayer.EVENTS.SELECT_CONTENT, {data: {category: 'Button', action: 'Click', label: 'Show loyalty club dialog when need to register or login'}});
                            return Util.openLoginDialog(true).then(function () {
                                if (User.session.userId) {
                                    return Util.showLoyaltyClubDialog();
                                }
                            });
                        });
                        return false;
                    } else {
                        return !isNaN(paramValue);
                    }
                }],
                show: ['Dialog', 'SpDialogUrlManager', '$rootScope', '$stateParams', function (Dialog, SpDialogUrlManager, $rootScope, $stateParams) {
                    return Dialog.show({
                        templateUrl: 'template/dialogs/loyalty/index.html',
                        ariaLabelledby: 'loyalty_dialog_title',
                        controller: 'LoyaltyCtrl as loyaltyCtrl',
                        controllerAs: 'loyaltyCtrl',
                        styleClass: 'loyalty',
                        classes: !!$stateParams.classes && JSON.parse($stateParams.classes),
                        disableClosing: !!$rootScope.config.retailer.loyaltyClubDriver.clientConfig.isRegisterToClubObligation,
                        hide: SpDialogUrlManager.backClose
                    });
                }]
            });
        }
    ]);
    app.controller('LoyaltyCtrl', [
        '$q', '$rootScope', '$timeout', '$stateParams', 'Api', 'Util', 'Dialog', 'SpDialogUrlManager', 'User', 'Config', 'Cart', 'SP_SERVICES', 'LocalStorage', 'SpCaptcha', 'DataLayer',
        function ($q, $rootScope, $timeout, $stateParams, Api, Util, Dialog, SpDialogUrlManager, User, Config, Cart, SP_SERVICES, localStorage, SpCaptcha, DataLayer) {
            var loyaltyCtrl = this;

            angular.extend(loyaltyCtrl, {
                user: {id: localStorage.getItem('session').userId},
                captchaIsInvalid: false,
                hide: hide,
                registerMembership: registerMembership,
                lookupMembership: lookupMembership,
                validateForm: validateForm,
                selectDriver: selectDriver,
                resetDrivers: resetDrivers,

            });

            resetDrivers();

            function _init () {
                loyaltyCtrl.user = User.data;
                loyaltyCtrl.userClubsByDriversObject = {};
                angular.forEach((User.data.loyaltyClubs || []), function (loyaltyClub) {
                    loyaltyClub.isExpired = !!loyaltyClub.loyaltyClubExpiration && new Date(loyaltyClub.loyaltyClubExpiration) < new Date();
                    loyaltyCtrl.userClubsByDriversObject[loyaltyClub.loyaltyClubDriverId] = loyaltyClub;
                });

                loyaltyCtrl.isMultiLoyalty = ($rootScope.config.retailer.loyaltyClubDrivers|| [])
                    .filter(function (driver) {
                        return driver.isActive
                    }).length > 1;

                if (!loyaltyCtrl.isMultiLoyalty) {
                    loyaltyCtrl.loyaltyClubDriver = $rootScope.config.retailer.loyaltyClubDrivers[0];
                    _initDriver(loyaltyCtrl.loyaltyClubDriver);
                } else {
                    _checkLoyaltyObligation();
                }

            }

            function _initDriver(loyaltyClubDriver) {
                return Api.request({
                    method: 'GET',
                    url: '/retailers/:rid/loyalty/fields',
                    params: {
                        retailerLoyaltyClubDriverId: loyaltyClubDriver.id
                    }
                }, {
                    fireAndForgot: !!$stateParams.fireAndForgot && JSON.parse($stateParams.fireAndForgot)
                }).then(function (fields) {
                    loyaltyCtrl.registerData = {};
                    loyaltyCtrl.lookupData = {};
                    loyaltyCtrl.loyaltyClubDriver = loyaltyClubDriver;
                    loyaltyCtrl.lookupFields = fields.lookup;
                    loyaltyCtrl.registerFields = fields.register;
                    loyaltyCtrl.clientConfig = loyaltyCtrl.loyaltyClubDriver.clientConfig;
                    if (loyaltyCtrl.clientConfig) {
                        loyaltyCtrl.joinClubActive = loyaltyCtrl.clientConfig.joinClubActive;
                        loyaltyCtrl.connectClubActive = loyaltyCtrl.clientConfig.connectClubActive;
                    }

                    fillUserDataToLoyaltyRegistrationForm(loyaltyCtrl.user);

                    if (loyaltyCtrl.user && loyaltyCtrl.user.loyaltyClubs) {
                        for (var i = 0; i < loyaltyCtrl.user.loyaltyClubs.length; i++) {
                            if (loyaltyCtrl.user.loyaltyClubs[i].RetailerLoyaltyClubDrivers_Id==loyaltyClubDriver.id) {
                                loyaltyCtrl.connected = true;
                            }
                        }
                    }

                    if (loyaltyCtrl.clientConfig &&  loyaltyCtrl.clientConfig.alertRegisterIfCartAbove && loyaltyCtrl.clientConfig.alertRegisterIfCartAbove < Cart.total.priceForView) {
                        loyaltyCtrl.alertRegisterIfCartAboveValue = Cart.total.simulateClubsGiftsForView;
                    }
                });
            }

            function _checkLoyaltyObligation() {
                var driver = Config.retailer.loyaltyClubDrivers.find(function (driver) {
                    return driver.clientConfig.isRegisterToClubObligation;
                });

                if (!!driver) {
                    var loyaltyProduct = Object.values(Cart.lines).find(function (line) {
                        var metaData = line.metaData && JSON.stringify(line.metaData);
                        return (metaData.registerData && metaData.registerData.retailerLoyaltyClubDriverId === driver.id && line.type === SP_SERVICES.CART_LINE_TYPES.REGISTER_LOYALTY && line.quantity);
                    });

                    if (!loyaltyProduct) {
                        _initDriver(driver);
                    }
                }
            }

            function selectDriver(driver) {
                return _initDriver(driver);
            }

            function resetDrivers() {
                loyaltyCtrl.registerData = loyaltyCtrl.lookupData = loyaltyCtrl.loyaltyClubDriver = loyaltyCtrl.lookupFields = loyaltyCtrl.registerFields = loyaltyCtrl.clientConfig = null;
                loyaltyCtrl.connected = false;

                return User.getData(true).then(function () {
                    _init();
                });
            }

            function registerMembership(form, event) {
                DataLayer.push(DataLayer.EVENTS.SELECT_CONTENT, {data: {category: 'Button', action: 'Click', label: 'Register club membership'}});

                loyaltyCtrl.showError = false;
                var cardCode = loyaltyCtrl.registerData && loyaltyCtrl.registerData.LoyaltyCardId;
                if(cardCode){
                    var loyaltyValidation = validateCardCode(cardCode);
                    if (!loyaltyValidation) {
                        loyaltyCtrl.showError = true;
                        return;
                    }
                }


                if (!!form && !!event && !loyaltyCtrl.validateForm(form, event)) {
                    return;
                }

                loyaltyCtrl.registerData.branchId = Config.branch.id;

                if (loyaltyCtrl.clientConfig && loyaltyCtrl.clientConfig.url && loyaltyCtrl.clientConfig.url.registrationUrl) {
                    SpDialogUrlManager.backClose();
                    $timeout(function () {
                        return Dialog.hide(null, 'fadeOut' + (Config.language.direction === 'rtl' ? 'Left' : 'Right'))
                            .then(function () {
                                return Util.showLoyaltyRegisterIFrameDialog({
                                    show: 'fadeIn' + (Config.language.direction === 'rtl' ? 'Right' : 'Left'),
                                    hide: 'zoomOut'
                                }, true);
                            }).then(function() {
                                // make sure the cart exists
                                return Cart.createCart();
                            }).then(function () {
                                return User.verifyLoyaltyRegistration(Cart.serverCartId, loyaltyCtrl.loyaltyClubDriver.id);
                            }).then(function () {
                                if (loyaltyCtrl.user.loyaltyVerificationStatus) {
                                    Util.showCommonDialog({
                                        title: '{{\'New member\' | translate}}',
                                        content: loyaltyCtrl.clientConfig.text.registrationCompletedSuccessfully || ''
                                    });
                                }
                            });
                    });
                }
                else {
                    loyaltyCtrl.registerData.retailerLoyaltyClubDriverId = loyaltyCtrl.loyaltyClubDriver.id;

                    if (loyaltyCtrl.loyaltyClubDriver.clientConfig &&
                        loyaltyCtrl.loyaltyClubDriver.clientConfig.product &&
                        loyaltyCtrl.loyaltyClubDriver.clientConfig.product.registerRetailerProductId) {
                        return Api.request({
                            method: 'GET',
                            url: '/v2/retailers/:rid/loyalty',
                            params: {
                                isDefault: true,
                                start: 0,
                                max: 1,
                                orderBy: 'Id',
                                loyaltyClubDriverIds: [loyaltyCtrl.loyaltyClubDriver.loyaltyClubDriverId]
                            }
                        }, {
                            withoutToast: true
                        }).then(function (result) {
                            var loyaltyId;
                            if (result.clubs && result.clubs.length) {
                                loyaltyId = result.clubs[0].id;
                            }

                            if (!loyaltyId) {
                                return $q.reject('No default loyalty club found');
                            }

                            var names = {};
                            names[Config.language.id] = {short: ''};
                            Cart.addLine({
                                product: {
                                    id: loyaltyCtrl.loyaltyClubDriver.clientConfig.product.registerRetailerProductId,
                                    names: names
                                },
                                quantity: 1,
                                type: SP_SERVICES.CART_LINE_TYPES.REGISTER_LOYALTY,
                                metaData: JSON.stringify({
                                    club: loyaltyId,
                                    registerData: loyaltyCtrl.registerData
                                })
                            }, null, {source: 'Loyalty Club'});
                            loyaltyCtrl.connected = true;
                            $rootScope.$emit('loyaltyClubRegistration', loyaltyCtrl.user.id);
                            _setFocusOnNextScreen();
                        }).catch(function () {
                            loyaltyCtrl.showError = true;
                            loyaltyCtrl.errorMessage = '';
                        });
                    }

                    // first make sure a cart exists
                    return Cart.createCart().then(function() {
                        return Api.request({
                            method: 'POST',
                            url: LOYALTY_URL,
                            data: {
                                registerData: loyaltyCtrl.registerData,
                                cartId: Cart.serverCartId
                            }
                        }, {
                            withoutToast: true
                        });
                    }).then(function (res) {
                        if (!res.loyaltyId) {
                            return $q.reject('No Loyalty Id');
                        }

                        _updateUserData();
                        loyaltyCtrl.connected = true;
                        $rootScope.$emit('loyaltyClubRegistration', loyaltyCtrl.user.id);
                        _setFocusOnNextScreen();
                    }).catch(function (res) {
                        Util.setFirstErrorInput(document.getElementById('membershipForm'));
                        loyaltyCtrl.showError = true;
                        if (res.response && res.response.errors) {
                            loyaltyCtrl.errorMessage = res.response.errors[0].message;
                        }
                    });
                }
            }

            function lookupMembership(form, event) {

                var recaptchaHash = SpCaptcha.getLastCaptchaVerifyHash();
                if (!recaptchaHash) {
                    loyaltyCtrl.captchaIsInvalid = true;
                    return;
                }
                var cardCode = loyaltyCtrl.lookupData && loyaltyCtrl.lookupData.LoyaltyCardId;
                if (cardCode){
                    var loyaltyValidation = validateCardCode(cardCode);
                    if (!loyaltyValidation) {
                        loyaltyCtrl.showError = true;
                        return;
                    }
                }

                DataLayer.push(DataLayer.EVENTS.SELECT_CONTENT, {data: {category: 'Button', action: 'Click', label: 'Lookup club membership'}});

                loyaltyCtrl.showError = false;

                if (!!form && !!event && !loyaltyCtrl.validateForm(form, event)) {
                    return;
                }

                loyaltyCtrl.lookupData.retailerLoyaltyClubDriverId = loyaltyCtrl.loyaltyClubDriver.id;

                return Config.initPromise.then(function() {
                    return Api.request({
                        method: 'PUT',
                        url: LOYALTY_URL,
                        params: {
                            branchId: Config.branch.id,
                            recaptchaHash: recaptchaHash
                        },
                        data: loyaltyCtrl.lookupData
                    }, {
                        withoutToast: true
                    });
                }).then(function () {
                    loyaltyCtrl.connected = true;
                    _updateUserData();
                    _setFocusOnNextScreen();
                }).catch(function (resp) {
                    // if (resp.statusCode != 404) return;
                    Util.setFirstErrorInput(document.querySelectorAll('#membershipForm, #membershipLookupForm'));

                    loyaltyCtrl.showError = true;
                });
            }

            function validateForm(form, event) {
                var formElement = _getEventFormElement(event);

                Util.setServerErrorToForm(form, formElement, '');

                if (form.$invalid) {
                    Util.setFirstErrorInput(formElement);
                    return false;
                }

                return true;
            }

            function validateCardCode(cardId) {
                cardId = cardId.toString();
                var validationData = loyaltyCtrl.clientConfig && loyaltyCtrl.clientConfig.validationData || {},
                    validate = !!cardId;
                if (validationData.characterLength && validate) {
                    validate = cardId.length == validationData.characterLength;
                }
                if (validationData.cardNumberType && validate) {
                    var regex = validationData.cardNumberType === 1 ? /^[0-9]+$/ : /^[a-z0-9]+$/i
                    validate = regex.test(cardId);
                }
                if (validationData.cardPrefix && validationData.cardPrefix.length && validate) {
                    validate = validationData.cardPrefix.some(function (item) {
                        return item.prefix && cardId.startsWith(item.prefix);
                    });
                }
                return validate;
            }

            function hide(result, classes) {
                SpDialogUrlManager.backClose();
                return Dialog.hide(result, classes);
            }

            function _getEventFormElement(event) {
                var element = event.target || event.srcElement || event.path[0];

                while (element && element.tagName.toUpperCase() !== 'FORM') {
                    element = element.parentElement || element.parentNode;
                }

                return element;
            }

            function _updateUserData() {
                return User.getData(true).then(function (data) {
                    loyaltyCtrl.user = data;
                    fillUserDataToLoyaltyRegistrationForm(loyaltyCtrl.user);
                });
            }

            function fillUserDataToLoyaltyRegistrationForm(userData) {
                if (!userData || !userData.id || !loyaltyCtrl.registerFields) {
                    return;
                }

                var genders = {
                    1: 'male',
                    2: 'female',
                    3: 'prefer_not_to_say'
                }

                angular.forEach(loyaltyCtrl.registerFields, function (registerField) {
                    switch(registerField.key) {
                        case 'Email':
                            if(userData.email) loyaltyCtrl.registerData['Email'] = userData.email;
                            break;
                        case 'FirstName':
                            if(userData.firstName) loyaltyCtrl.registerData['FirstName'] = userData.firstName;
                            break;
                        case 'LastName':
                            if(userData.lastName) loyaltyCtrl.registerData['LastName'] = userData.lastName;
                            break;
                        case 'City':
                            if(userData.addresses[0] && userData.addresses[0].city) loyaltyCtrl.registerData['City'] = userData.addresses[0].city;
                            break;
                        case 'BirthDate':
                            if(userData.dateOfBirth) loyaltyCtrl.registerData['BirthDate'] = new Date(userData.dateOfBirth);
                            break;
                        case 'Gender':
                            if(userData.gender) loyaltyCtrl.registerData['Gender'] = genders[userData.gender] || null;
                            break;
                    }
                });

                loyaltyCtrl.registerHasData = !!(loyaltyCtrl.registerData && Object.keys(loyaltyCtrl.registerData).length);
            }

            function _setFocusOnNextScreen() {
                $timeout(function () {
                    var continueBtn;
                    while (!continueBtn) {
                        continueBtn =  document.getElementById('continue_shop_btn');
                    }
                    continueBtn && continueBtn.focus();
                });
            }

            _updateUserData();
        }
    ])
    //todo: this controller should be only in the prutah template
    .controller('LoyaltyPrutahCtrl', ['$scope', '$rootScope', 'Util', function ($scope, $rootScope, Util) {
        var loyaltyCtrl = $scope.loyaltyCtrl,
            _dataInFocus;

        angular.extend(loyaltyCtrl, {
            connectMembership: connectMembership,
            onDataChange: onDataChange,
            setFocus: setFocus,
            clearData : clearData
        });

        function connectMembership(form, event) {
            loyaltyCtrl.showError = false;

            if (!loyaltyCtrl.validateForm(form, event)) {
                return;
            }

            if (loyaltyCtrl.lookupHasData) {
                return loyaltyCtrl.lookupMembership();
            } else if (loyaltyCtrl.registerHasData) {
                return loyaltyCtrl.registerMembership();
            }

            Util.setFirstErrorInput(document.getElementById('membershipForm'));
        }

        function onDataChange(dataName) {
            $rootScope.$emit('spInlineError.clear');

            loyaltyCtrl.showError = false;
            loyaltyCtrl[dataName + 'HasData'] = false;
            var data = loyaltyCtrl[dataName + 'Data'],
                props = Object.keys(data);
            for (var i = 0; i < props.length; i++) {
                if (!data[props[i]]) {
                    continue;
                }

                loyaltyCtrl[dataName + 'HasData'] = true;
                return;
            }
        }

        function setFocus(dataName) {
            if (dataName !=_dataInFocus) {
                $rootScope.$emit('spInlineError.clear');
            }

            _dataInFocus = dataName;
        }

        function clearData(from) {
            loyaltyCtrl[from + 'Data'] = {};
            onDataChange(from);
        }
    }])
    //todo: this controller should be only in the kikar template
    .controller('LoyaltyKikarCtrl', ['$scope', '$rootScope', 'DataLayer', function ($scope, $rootScope, DataLayer) {
        var loyaltyCtrl = $scope.loyaltyCtrl;

        angular.extend(loyaltyCtrl, {
            registerNewMember: registerNewMember,
            existMember: existMember,
            isRegisterNotInProgress: isRegisterNotInProgress,
            isRegisterEnabled: isRegisterEnabled,
            isLookupEnabled: isLookupEnabled
        });

        function registerNewMember() {
            loyaltyCtrl.showError = false;
            loyaltyCtrl.registerMembership();

            DataLayer.push(DataLayer.EVENTS.SELECT_CONTENT, {data: {category: 'Button', action: 'Click', label: 'Register New Loyalty Member'}});
        }

        function existMember() {
            loyaltyCtrl.showError = false;
            loyaltyCtrl.lookupMembership();

            DataLayer.push(DataLayer.EVENTS.SELECT_CONTENT, {data: {category: 'Button', action: 'Click', label: 'Register New Loyalty Member'}});
        }

        function isRegisterNotInProgress() {
            return (loyaltyCtrl.loyaltyClubDriver && loyaltyCtrl.user.loyaltyVerificationStatus == null) &&
                (!loyaltyCtrl.userClubsByDriversObject[loyaltyCtrl.loyaltyClubDriver.loyaltyClubDriverId] || !loyaltyCtrl.userClubsByDriversObject[loyaltyCtrl.loyaltyClubDriver.loyaltyClubDriverId].loyaltyCardId)
                && loyaltyCtrl.loyaltyAtProcess === undefined;
        }

        function isRegisterEnabled() {
            return !loyaltyCtrl.loyaltyClubDriver.isLookupOnly && isRegisterNotInProgress();
        }

        function isLookupEnabled() {
            return !loyaltyCtrl.loyaltyClubDriver.isRegisterOnly;
        }

    }]);
})(angular, app);
