
(function (angular, app) {
    'use strict';

    app.filter('cartLineQuantity', ['$filter', function ($filter) {
        var _productWeightFilter = $filter('productWeight');
        var _unitNamesFilter = $filter('unitNames'),
            _nameFilter = $filter('name');

        /**
         * @param {Object} line
         */
        return function (line, actualQuantity, soldBy, isIgnoreOutOfStock) {
            if (line.isProductOutOfStock && !isIgnoreOutOfStock) return 0;
            
            if (line.isPseudo) return line.quantity || 0;
            
            // "not weightable" => quantity only (without unit name)
            if(line.isCase || !line.product.isWeighable) return actualQuantity || line.quantity;

            // "weightable" and "sale by unit but need to weight" => quantity only (without unit name)
            if(line.product.isWeighable && line.product.weight && (!soldBy || soldBy === 'Unit')) return (actualQuantity || line.quantity);

            // sold by weight have unit name (gram, kg)
            return _productWeightFilter(line.product, line.isCase, null, actualQuantity || line.quantity, soldBy);
            
            // ex code
            // return line.isCase || !line.product.isWeighable || (line.product.isWeighable && line.product.weight && (!soldBy || soldBy === 'Unit')) ? (actualQuantity || line.quantity) : _productWeightFilter(line.product, line.isCase, null, actualQuantity || line.quantity, soldBy);
        };
    }]);

})(angular, app);
