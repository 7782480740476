(function (angular, app) {
    'use strict';

    app
        .config(['$stateProvider', 'ROUTE_ACCESS', function ($stateProvider, ROUTE_ACCESS) {
            $stateProvider.state('app.userEdit.extendedLoyalty', {
                url: '/extended-loyalty',
                data: {
                    routeAccess: [ROUTE_ACCESS.NOT_ORGANIZATION_MEMBER]
                },
                views: {
                    'tab': {
                        templateUrl: 'template/views/user-edit/extended-loyalty-club/index.html',
                        controller: 'ExtendedLoyaltyCtrl',
                        controllerAs: 'extendedLoyaltyCtrl'
                    }
                }
            });
        }])
        .controller('ExtendedLoyaltyCtrl', [
            '$scope', '$rootScope', '$state', '$timeout', 'Api', 'Config', '$filter', 'User', 'LocalStorage', 'Specials',
            'PermanentFilters', 'Cart', 'Util', 'SpCaptcha',
            function ($scope, $rootScope, $state, $timeout, Api, Config, $filter, User, LocalStorage, Specials,
                      PermanentFilters, Cart, Util, SpCaptcha) {
                var extendedLoyaltyCtrl = this,
                    userEditCtrl = $scope.userEditCtrl,
                    _translate = $filter('translate');

                extendedLoyaltyCtrl.loyaltyClubDriver = !!Config.retailer.loyaltyClubDrivers && Config.retailer.loyaltyClubDrivers[0];
                extendedLoyaltyCtrl.registerData = {};
                extendedLoyaltyCtrl.registerData.allowSendPromotions = true;
                extendedLoyaltyCtrl.showCaptcha = true;
                extendedLoyaltyCtrl.registrationLevels = {
                    LOYALTY: 1,
                    WEBSITE: 2,
                    REGISTER: 3,
                    REGISTER_SUCCESS: 4,
                    REGISTERED: 5
                };
                extendedLoyaltyCtrl.currentLevel = extendedLoyaltyCtrl.registrationLevels.LOYALTY;
                extendedLoyaltyCtrl.isUserLoggedIn = !!userEditCtrl.user;
                extendedLoyaltyCtrl.next = next;
                extendedLoyaltyCtrl.loadSpecials = loadSpecials;
                extendedLoyaltyCtrl.specialsActions = {
                    openCarouselItem: openSpecial
                };

                extendedLoyaltyCtrl.specialsCarouselWatchers = {
                    'cart.lines.add': onCartLinesAdd
                };

                extendedLoyaltyCtrl.radioOptions = [{value: $rootScope.GENDERS.MALE, label: 'Male'}, {value: $rootScope.GENDERS.FEMALE, label: 'Female'}, {value: $rootScope.GENDERS.PREFER_NOT_TO_SAY, label: 'Prefer not to say'}];

                _setLoyaltyInfo();
                _generateTitles();

                function next(formName, nextLevel, event) {
                    if (formName && !_validateForm(formName, event)) {
                        return;
                    }

                    extendedLoyaltyCtrl.currentLevel = nextLevel;
                    _generateTitles();

                    if (extendedLoyaltyCtrl.currentLevel === extendedLoyaltyCtrl.registrationLevels.REGISTER) {
                        _register(formName, event);
                    }
                }

                function loadSpecials(from, size, callback) {
                    Specials.getSpecials(Specials.prepareSearchParams({
                        from: from,
                        size: size,
                        filters: {
                            must: {
                                exists: ['loyaltyClubsIds']
                            }
                        }
                    })).then(function (specials) {
                        angular.forEach(specials.specials, function (special) {
                            if (special.item) {
                                special.item = Cart.getProduct(special.item);
                            }
                        });

                        callback(null, specials.total, specials.specials);
                    }).catch(function (err) {
                        callback(err);
                    });
                }

                function openSpecial(special) {
                    if (special.item) {
                        return Util.openProduct(special.item, {
                            source: 'Loyalty Club'
                        });
                    }

                    $state.go('app.specials.special', {sid: special.id});
                }

                function onCartLinesAdd(carouselSpecials) {
                    angular.forEach(carouselSpecials, function (special) {
                        special.item && (special.item = Cart.getProduct(special.item));
                    });
                }

                function _generateTitles() {
                    if(extendedLoyaltyCtrl.currentLevel === extendedLoyaltyCtrl.registrationLevels.REGISTER_SUCCESS) {
                        extendedLoyaltyCtrl.title =  extendedLoyaltyCtrl.registerData.firstName + ', ' + _translate('You have successfully registered');
                        extendedLoyaltyCtrl.description = _translate('For the Customer club' + extendedLoyaltyCtrl.isUserLoggedIn ? '' : ' and the Website');
                    } else if (extendedLoyaltyCtrl.currentLevel === extendedLoyaltyCtrl.registrationLevels.REGISTERED) {
                        extendedLoyaltyCtrl.title = _translate('Membership Club');
                        extendedLoyaltyCtrl.description = null;
                    } else {
                        extendedLoyaltyCtrl.title =  extendedLoyaltyCtrl.loyaltyClubDriver.clientConfig.extendedLoyaltyClubMainTitle;
                        extendedLoyaltyCtrl.description = extendedLoyaltyCtrl.loyaltyClubDriver.clientConfig.extendedLoyaltyClubDescription;
                    }
                }

                function _setLoyaltyInfo() {
                    if (extendedLoyaltyCtrl.isUserLoggedIn) {
                            if (!userEditCtrl.user.loyaltyClubCardId) {
                                extendedLoyaltyCtrl.registerData.firstName = userEditCtrl.user.firstName;
                                extendedLoyaltyCtrl.registerData.lastName = userEditCtrl.user.lastName;
                                extendedLoyaltyCtrl.registerData.email = userEditCtrl.user.email;
                                extendedLoyaltyCtrl.registerData.phoneNum = userEditCtrl.user.phones.length && userEditCtrl.user.phones[0].phoneNumber;
                                extendedLoyaltyCtrl.registerData.birthDate = userEditCtrl.user.dateOfBirth;
                                extendedLoyaltyCtrl.registerData.city = userEditCtrl.user.addresses && userEditCtrl.user.addresses[0] && userEditCtrl.user.addresses[0].city;
                                return;
                            }

                        extendedLoyaltyCtrl.userData = userEditCtrl.user;

                        if (extendedLoyaltyCtrl.currentLevel !== extendedLoyaltyCtrl.registrationLevels.REGISTER_SUCCESS) {
                                extendedLoyaltyCtrl.currentLevel = extendedLoyaltyCtrl.registrationLevels.REGISTERED;
                            }
                    }
                }

                function _register(formName, event) {
                    var recaptchaHash = SpCaptcha.getLastCaptchaVerifyHash();
                    extendedLoyaltyCtrl.registerData.recaptchaHash = recaptchaHash;
                    extendedLoyaltyCtrl.showCaptcha = false;
                    return Api.request({
                        method: 'POST',
                        url: extendedLoyaltyCtrl.isUserLoggedIn ? '/retailers/:rid/users/:uid/loyalty/_extended-registration' : '/retailers/:rid/loyalty/_extended-registration',
                        data: extendedLoyaltyCtrl.registerData
                    }).then(function () {
                        extendedLoyaltyCtrl.currentLevel = extendedLoyaltyCtrl.registrationLevels.REGISTER_SUCCESS;

                        if (extendedLoyaltyCtrl.isUserLoggedIn) {
                            _setUserData();
                            return;
                        }

                        return User.login(extendedLoyaltyCtrl.registerData.email, extendedLoyaltyCtrl.registerData.password).then(function () {
                            extendedLoyaltyCtrl.isUserLoggedIn = true;
                            _setUserData();
                        });
                    }).catch(function (res) {
                        var formElement = _getEventFormElement(event);
                        var error = (res.response.errors && res.response.errors.length && (res.response.errors[0].message || res.response.errors[0].msg)) || res;
                        extendedLoyaltyCtrl.showCaptcha = true;
                        extendedLoyaltyCtrl.captchaIsInvalid = true;
                        Util.setServerErrorToForm(formName, formElement, error);
                    }).finally(function () {
                        if (Util.isLoyaltyPremiumPackageEnabled()) {
                            return $state.go('app.userEdit.premiumLoyaltyClub', {loyaltyClubDriverId: extendedLoyaltyCtrl.loyaltyClubDriver.loyaltyClubDriverId});
                        }
                        extendedLoyaltyCtrl.registerSuccess = true
          /*              $timeout(function () {
                            var customerCardElement = document.querySelector('.extended-loyalty .content .customer-details .customer-card');
                            customerCardElement && customerCardElement.focus();
                        }, 1000);*/
                    });
                }

                function _setUserData() {
                    return User.getData(true).then(function (data) {
                        userEditCtrl.user = data;
                        _setLoyaltyInfo();
                        _generateTitles();
                    });
                }

                function _validateForm(form, event) {
                    var formElement = _getEventFormElement(event);

                    if (form.$invalid) {
                        Util.setFirstErrorInput(formElement);
                        angular.forEach(form.$error.required, function(field) {
                            if (field.$$attr.id === 'captcha_hidden_input') {
                                extendedLoyaltyCtrl.captchaIsInvalid = true;
                            }
                        });
                        return false;
                    }else if (extendedLoyaltyCtrl.currentLevel === extendedLoyaltyCtrl.registrationLevels.LOYALTY && !extendedLoyaltyCtrl.registerData.bylaws) {
                        Util.setServerErrorToForm(form, formElement, {
                            response: {
                                errors: [{
                                    param: 'loyaltyPolicyApproval',
                                    msg: 'required'
                                }]
                            }
                        });
                        return false;
                    } else if (extendedLoyaltyCtrl.currentLevel === extendedLoyaltyCtrl.registrationLevels.WEBSITE && !extendedLoyaltyCtrl.registerData.policyApproval) {
                        Util.setServerErrorToForm(form, formElement, {
                            response: {
                                errors: [{
                                    param: 'policyApproval',
                                    msg: 'required'
                                }]
                            }
                        });
                        return false;
                    } else if (extendedLoyaltyCtrl.registerData.password !== extendedLoyaltyCtrl.registerData.confirmPassword) {
                        Util.setServerErrorToForm(form, formElement, {
                            response: {
                                errors: [{
                                    param: 'confirmPassword',
                                    msg: 'Passwords do not match'
                                }]
                            }
                        });
                        return false;
                    }

                    return true;
                }

                function _getEventFormElement(event) {
                    var element = event.target || event.srcElement || event.path[0];

                    while (element && element.tagName.toUpperCase() !== 'FORM') {
                        element = element.parentElement || element.parentNode;
                    }

                    return element;
                }

            }
        ]);
})(angular, app);