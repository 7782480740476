(function (angular, app) {
    'use strict';

    app.config(['$stateProvider', 'ROUTE_ACCESS', function ($stateProvider, ROUTE_ACCESS) {
        $stateProvider.state('app.userEdit.accountInfo', {
            url: '',
            data: {
                routeAccess: ROUTE_ACCESS.LOGIN
            },
            metaTags: {
                title: ['$filter', function ($filter) {
                    return ($filter('translate')('Account Info'));
                }]
            },
            views: {
                'tab': {
                    templateUrl: 'template/views/user-edit/account-info/index.html',
                    controller: 'AccountInfoCtrl',
                    controllerAs: 'accountInfoCtrl',
                    resolve: {
                        isEnabledAddressSettings: ['AddressService', function (AddressService) {
                            return AddressService.isEnabledAddressSetting().then(function (res) {
                                return res.isEnabled;
                            })
                        }],
                        zipCodeLimitLength:['Config', function (config) {
                            var _US_ZIP_CODE_LENGTH = 5;
                            return config.retailer.currencyCode === 'USD' ? _US_ZIP_CODE_LENGTH : undefined;
                        }]
                    }
                }
            }
        });
    }]).controller('AccountInfoCtrl', [
        '$scope', '$q', '$filter', 'Util', 'Api', 'User', 'SeniorCitizenVerificationDialog', 'Loyalty', 'HOUSE_ENTRIES', 'CITY_TYPES', 'Config', 'LocalStorage', 
        '$rootScope', 'isEnabledAddressSettings', 'AddressService', 'SP_SERVICES', 'FILTER_ADDRESS_MODE', '$timeout', 'GOOGLE_MAP_ADDRESS_TYPES', 'Dialog', 'GoogleMapService', 'zipCodeLimitLength',
        function ($scope, $q, $filter, Util, Api, User, SeniorCitizenVerificationDialog, Loyalty, HOUSE_ENTRIES, CITY_TYPES, config, localStorageService,
            $rootScope, isEnabledAddressSettings, AddressService, SP_SERVICES, FILTER_ADDRESS_MODE, $timeout, GOOGLE_MAP_ADDRESS_TYPES, dialog, GoogleMapService, zipCodeLimitLength
        ) {
            var accountInfoCtrl = this,
                userEditCtrl = $scope.userEditCtrl,
                translateFilter = $filter('translate');

            accountInfoCtrl.isEnabledAddressSettings = isEnabledAddressSettings;
            accountInfoCtrl.deliveryAreaMethod = config.retailer.deliveryAreaMethod
            accountInfoCtrl.isUseGMapDeliveryMethod = [SP_SERVICES.DELIVERY_AREA_METHODS.GOOGLE_MAPS, SP_SERVICES.DELIVERY_AREA_METHODS.POLYGON].includes(accountInfoCtrl.deliveryAreaMethod);
            accountInfoCtrl.isGMapWithZipCodeProvider = config.retailer.settings.autocompleteAddressField && config.retailer.settings.activeZipProvider && config.retailer.settings.activeZipProvider.name === 'Get Address';
            accountInfoCtrl.isZipCodeValid = false;
            accountInfoCtrl.droppinDisableFields = {
                city: true,
                text1: true,
                zipCode: true,
                state: true
            };
            accountInfoCtrl.isAddressVerificationEnabled = config.retailer.settings.isAddressVerificationEnabled === 'true' && accountInfoCtrl.isUseGMapDeliveryMethod;
            accountInfoCtrl.addressVerificationText = config.retailer.settings.addressVerificationText ? JSON.parse(config.retailer.settings.addressVerificationText) : {};
            accountInfoCtrl.addressVerificationTextDroppinOff = JSON.parse(config.retailer.settings.addressVerificationTextDroppinOff || '{}');
            accountInfoCtrl.formErrorCtrl = {
                city: false,
                text1: false,
                zipCode: false
            };
            accountInfoCtrl.backupAddress = {};
            accountInfoCtrl.isRunAddressAutoComplete = true;
            accountInfoCtrl.zipCodeLimitLength = zipCodeLimitLength;

            var _ADDRESS_TYPE_MAP = {
                city: 'city',
                text1: 'address',
                zipCode: 'zipCode'

            };
            var _addressComponents = ['city', 'text1', 'zipCode'];
            var _DEFAULT_ERROR_MESSAGE = {
                city: 'Sorry, Your city was not found. Please select an address from auto-complete suggestions or pin your address using the google maps option.',
                address: 'Sorry, Your address was not found. Please select an address from auto-complete suggestions or pin your address using the google maps option.',
                zipCode: 'Sorry, Your zip code was not found. Please select an address from auto-complete suggestions or pin your address using the Google Maps option.'
            };
            var _DEFAULT_ERROR_MESSAGE_NON_DROP_PIN = {
                  city: 'autocomplete_city_not_found_non_droppin',
                  address: 'autocomplete_address_not_found_non_droppin',
                  zipCode: 'autocomplete_zip_code_not_found_non_droppin'
            };
            var _wasText1HouseNumberShown = false;
            var _houseNumberRegExp = new RegExp(/^[0-9 \-]+$/);
            
            accountInfoCtrl.loyaltyClubDriver = Loyalty.getRetailerClubDriver();

            User.getData(true).then(function (userData) {
                userEditCtrl.user = userData;
                userData.dateOfBirth = new Date(userData.dateOfBirth);
                
                userEditCtrl.resetUser(userData);
                _setUserPoints();

                if (userEditCtrl.user.addresses[0]) {
                    var _address = userEditCtrl.user.addresses[0];
                    _address.isSuggestedAddress = false;

                    var cloneAddress = angular.copy(_address, {});
                    angular.extend(accountInfoCtrl.backupAddress, cloneAddress);
                    accountInfoCtrl.previousFullDeliveryAddress = angular.copy({
                        address: cloneAddress,
                        externalPlaceId: cloneAddress.externalPlaceId
                    }, {});
                }
                onZipCodeBlur();
            });

            if ($scope.$parent.resetUser) {
                User.getData(true).then(function (userData) {
                    $scope.$parent.resetUser = false;

                    userData.dateOfBirth = new Date(userData.dateOfBirth);

                    userEditCtrl.resetUser(userData);
                });
            }

            accountInfoCtrl.save = save;
            accountInfoCtrl.getStreetAutoCompleteOptions = getStreetAutoCompleteOptions;
            accountInfoCtrl.getEntriesAutoComplete = getEntriesAutoComplete;
            accountInfoCtrl.onCityChosen = onCityChosen;
            accountInfoCtrl.isSeniorCitizenChange = isSeniorCitizenChange;
            accountInfoCtrl.checkForAutocompleteAddress = checkForAutocompleteAddress;
            accountInfoCtrl.isShowAddressField = isShowAddressField;
            accountInfoCtrl.isJustDropedNotDetectedAddress = isJustDropedNotDetectedAddress;
            accountInfoCtrl.isShowStreetAndHouseNumberFields = isShowStreetAndHouseNumberFields;
            accountInfoCtrl.isDisableAddressField = isDisableAddressField;
            accountInfoCtrl.getCityAutoCompleteOptions = getCityAutoCompleteOptions;
            accountInfoCtrl.onAutoCompleteCityChosen = onAutoCompleteCityChosen;
            accountInfoCtrl.onCityChange = onCityChange;
            accountInfoCtrl.onCityBlur = onCityBlur;
            accountInfoCtrl.onAddressChosen = onAddressChosen;
            accountInfoCtrl.onAddressChange = onAddressChange;
            accountInfoCtrl.onAddressInputKeyDown = onAddressInputKeyDown;
            accountInfoCtrl.getAddressAutoCompleteOptions = getAddressAutoCompleteOptions;
            accountInfoCtrl.onAddressBlur = onAddressBlur;
            accountInfoCtrl.onZipCodeBlur = onZipCodeBlur;
            accountInfoCtrl.saveAddress = saveAddress;
            accountInfoCtrl.saveUserInfo = saveUserInfo;
            accountInfoCtrl.cancelPinnedAddress = cancelPinnedAddress;
            accountInfoCtrl.openGoogleMapDialog = openGoogleMapDialog;
            accountInfoCtrl.editDroppinAddressField = editDroppinAddressField;
            accountInfoCtrl.onDroppinAddressFieldBlur = onDroppinAddressFieldBlur;
            accountInfoCtrl.onZipCodeChange = onZipCodeChange;

            accountInfoCtrl.isSeniorCitizen = userEditCtrl.user.isSeniorCitizen;
            _setDateOfBirth();
            userEditCtrl.switchTab();

            if(
                // is in pilot updates
                accountInfoCtrl.isEnabledAddressSettings && 
                // is GOOGLE_MAPS / POLYGON
                accountInfoCtrl.isUseGMapDeliveryMethod
            ){
                // GOOGLE_MAPS / POLYGON with Zip-code provider will use old form => don't need to load form settings
                if (accountInfoCtrl.isGMapWithZipCodeProvider) {
                    accountInfoCtrl.streetIsRequire = config.retailer.settings.addressPrecision !== DELIVERY_AREA_METHODS_PRECISION.CITY_STREET_NOT_REQUIRED;
                    return;
                }

                accountInfoCtrl.streetIsRequire = true;
                delete config.retailer.settings.enableDefaultCountry;

                accountInfoCtrl.isGoogleMapDropPinAllow = config.retailer.settings.isGoogleMapDropPinAllow === 'true';
                accountInfoCtrl.isGoogleMapDropPinValidateHouseNumAndStreet = config.retailer.settings.isGoogleMapDropPinValidateHouseNumAndStreet === 'true';

                // already have address form settings
                if(!_isNullOrEmpty(config.retailer.settings.addressFormSettings) && !_isNullOrEmpty(config.retailer.settings.enableAddressFiltering) && !_isNullOrEmpty(config.retailer.settings.addressFiltering)){
                    accountInfoCtrl.addressFormSettings = JSON.parse(config.retailer.settings.addressFormSettings);
                    
                    accountInfoCtrl.enableAddressFiltering = config.retailer.settings.enableAddressFiltering === 'true';
                    accountInfoCtrl.addressFiltering = Number(config.retailer.settings.addressFiltering);
                    accountInfoCtrl.isAddressFilteringCityMode = accountInfoCtrl.enableAddressFiltering && accountInfoCtrl.addressFiltering === FILTER_ADDRESS_MODE.CITY;
                    accountInfoCtrl.isAddressFilteringZipCodeMode = accountInfoCtrl.enableAddressFiltering && accountInfoCtrl.addressFiltering === FILTER_ADDRESS_MODE.ZIP_CODE;
                }else{
                    // haven't had address form settings yet
                    AddressService.getAddressFormSettings().then(function (formSettings){
                        accountInfoCtrl.addressFormSettings = formSettings.settings;

                        accountInfoCtrl.enableAddressFiltering = !!formSettings.mode;
                        accountInfoCtrl.addressFiltering = formSettings.mode;
                        accountInfoCtrl.isAddressFilteringCityMode = accountInfoCtrl.enableAddressFiltering && accountInfoCtrl.addressFiltering === FILTER_ADDRESS_MODE.CITY;
                        accountInfoCtrl.isAddressFilteringZipCodeMode = accountInfoCtrl.enableAddressFiltering && accountInfoCtrl.addressFiltering === FILTER_ADDRESS_MODE.ZIP_CODE;
                    })
                }
            }

            function save(event) {
                var formElement = event.target || event.srcElement || event.path[0];

                $scope.$parent.resetUser = accountInfoCtrl.form.$invalid;

                if (accountInfoCtrl.form.$invalid) {
                    return Util.setFirstErrorInput(formElement);
                } else if (userEditCtrl.user.password !== userEditCtrl.user.confirmPassword) {
                    return Util.setServerErrorToForm(accountInfoCtrl.form, formElement, {
                        response: {
                            errors: [{
                                param: 'confirmPassword',
                                msg: 'Passwords do not match'
                            }]
                        }
                    });
                }

                // can only unselect is senior citizen through here
                var isSeniorCitizen;
                if (!accountInfoCtrl.isSeniorCitizen && userEditCtrl.user.isSeniorCitizen) {
                    isSeniorCitizen = false;
                }

                var userAddress = userEditCtrl.user.addresses[0]
                userAddress.entry = _getEntryValue(userAddress.entry);
                Util.cleanUserAddressObject(userAddress);

                userEditCtrl.formSubmited = true;
                if(userEditCtrl.user.phonesObj){
                    angular.forEach(userEditCtrl.user.phonesObj, function(phone){
                        if(userEditCtrl.editPhonesMode[phone.typeVal] && userEditCtrl.customPhoneValidation){
                            if(phone.customPhoneNumber && phone.customPhoneNumber !== '') {
                                phone.phoneNumber = [phone.areaCode ? phone.areaCode.toString() : '',
                                    phone.customPhoneNumber ? phone.customPhoneNumber.toString() : ''
                                ].join('');
                            }
                            else{
                                phone.phoneNumber = '';
                            }
                        }
                    })
                }

                if (userEditCtrl.savedUserAddress) {
                    var isCityChanged = userEditCtrl.savedUserAddress.city !== userAddress.city;
                    var isText1Changed = userEditCtrl.savedUserAddress.text1 !== userAddress.text1;
                    if (isCityChanged || isText1Changed) {
                        ['lat', 'lng', 'externalPlaceId'].forEach(function (field) {
                            userAddress[field] = null;
                        });
                    }
                }
                
                return Api.request({
                    method: 'PATCH',
                    url: '/retailers/:rid/users/:uid',
                    data: {
                        general: {
                            firstName: userEditCtrl.user.firstName,
                            lastName: userEditCtrl.user.lastName,
                            birthDate: userEditCtrl.user.dateOfBirth,
                            allowSendPromotions: userEditCtrl.user.allowSendPromotions,
                            gender: parseFloat(userEditCtrl.user.gender),
                            orderComments: userEditCtrl.user.orderComments
                        },
                        password: userEditCtrl.user.password,
                        phones: userEditCtrl.user.phonesObj,
                        address: userAddress,
                        isSeniorCitizen: isSeniorCitizen
                    }
                }).then(function () {
                    if (isSeniorCitizen === false) {
                        userEditCtrl.user.isSeniorCitizen = isSeniorCitizen;
                    }
                    Util.checkAndRemoveErrorMessage(formElement);
                    var userPhonesActiveInputs = Object.keys(userEditCtrl.editPhonesMode);
                    if (userPhonesActiveInputs && userPhonesActiveInputs.length) {

                        userPhonesActiveInputs.forEach(function (phoneType) {
                            if(userEditCtrl.user.phonesObj && userEditCtrl.user.phonesObj[phoneType] && userEditCtrl.user.phonesObj[phoneType].phoneNumber) {
                                userEditCtrl.compileCustomValidatedPhone(userEditCtrl.user.phonesObj[phoneType])
                            }
                        })
                        userEditCtrl.setPhones();
                    }
                    return Util.showCommonDialog({
                        title: '{{\'Successful Update\' | translate}}',
                        content: '{{\'Your account info has been successfully updated\' | translate}}.'
                    });
                }).catch(function (res) {
                    Util.setServerErrorToForm(accountInfoCtrl.form, formElement, res);
                });
            }

            function onCityChosen() {
                accountInfoCtrl.streetIsRequire = (userEditCtrl.citiesObject[userEditCtrl.user.addresses[0].city] && userEditCtrl.citiesObject[userEditCtrl.user.addresses[0].city].type === CITY_TYPES.MULTIPLE_ZIP_CODES);
            }

            function getStreetAutoCompleteOptions() {
                var street = userEditCtrl.user.addresses[0].street;
                if (!street) {
                    return $q.resolve();
                }

                var cityId = userEditCtrl.citiesObject[userEditCtrl.user.addresses[0].city] && userEditCtrl.citiesObject[userEditCtrl.user.addresses[0].city].id;
                if (!cityId) {
                    return $q.resolve();
                }

                return Api.request({
                    method: 'GET',
                    url: '/v2/addresses/streets',
                    params: {
                        term: street,
                        cityId: cityId,
                        size: 16
                    }
                }).then(function (data) {
                    return data.streets.map(function (street) {
                        return street.name;
                    });
                });
            }

            function getEntriesAutoComplete() {
                var result = [];
                angular.forEach(HOUSE_ENTRIES, function (entry) {
                    result.push(translateFilter(entry));
                });
                return result;
            }

            function _setDateOfBirth() {
                if (userEditCtrl.user.dateOfBirth) { // fix for input type date
                    userEditCtrl.user.dateOfBirth = new Date(userEditCtrl.user.dateOfBirth);
                }
            }

            function _setUserPoints() {
                if (!userEditCtrl.user || !accountInfoCtrl.loyaltyClubDriver) {
                    return;
                }

                accountInfoCtrl.userLoyaltyClub = Loyalty.getUserLoyaltyClub(userEditCtrl.user);
                if (!accountInfoCtrl.userLoyaltyClub) return;
                accountInfoCtrl.activeCashbackLoyalty = Loyalty.getRetailerClubConfig(accountInfoCtrl.userLoyaltyClub.loyaltyClubId);
                if (!accountInfoCtrl.activeCashbackLoyalty) return;
                if (accountInfoCtrl.activeCashbackLoyalty.pointsToCashback) {
                    accountInfoCtrl.userCashbackLoyalty = userEditCtrl.user.loyaltyClubs.length && userEditCtrl.user.loyaltyClubs.find(function (club) {
                        return club.loyaltyClubDriverId === accountInfoCtrl.loyaltyClubDriver.loyaltyClubDriverId;
                    });
                }

                if(accountInfoCtrl.userCashbackLoyalty) {
                    Util.getUserCashbackPoints(accountInfoCtrl.userCashbackLoyalty.id).then(function (response) {
                        userEditCtrl.user.points = (Number(userEditCtrl.user.points) || 0) +
                            (Number(response.points) || 0);
                    });
                }
            }

            function isSeniorCitizenChange() {
                if (!accountInfoCtrl.isSeniorCitizen || userEditCtrl.user.isSeniorCitizen) {
                    return;
                }

                return SeniorCitizenVerificationDialog.show().then(function () {
                    return User.getData();
                }).then(function (userData) {
                    userEditCtrl.resetUser(userData);
                    accountInfoCtrl.isSeniorCitizen = userData.isSeniorCitizen;
                });
            }


            function checkForAutocompleteAddress(evt) {
                Util.removeErrorsFromInputs();

                if (evt.target.disabled &&
                    config.retailer.settings.autocompleteAddressField) {
                    var formElement = angular.element(document.querySelector('.form'));
                    var serverRes = {
                        errors: [{
                            param: evt.target.name,
                            msg: 'not_editable'
                        }]
                    };
                    if(!config.retailer.settings.activeZipProvider.isLockedAddress) {
                        serverRes.errors[0].msg = 'not_editable';
                    } else if (config.retailer.settings.activeZipProvider.isLockedAddress) {
                        if (!userEditCtrl.user.addresses[0].city) {
                            serverRes.errors[0].msg = 'not_editable_locked_empty';
                        } else {
                            serverRes.errors[0].msg = 'not_editable_locked';
                        }
                    }

                    Util.setServerErrorToForm(accountInfoCtrl.form, formElement, {response: serverRes});
                }
            }

            /**
             * Get Entry Value
             * @private
             *
             * @param {String} entry
             *
             * @returns {String|null}
             */
            function _getEntryValue(entry) {
                if (entry === translateFilter(HOUSE_ENTRIES[0])) {
                    return null;
                }
                return entry;
            }

            // prevent case false (boolean)
            function _isNullOrEmpty(value){
                if (value === null || value === undefined || value === '') {
                    return true;
                }
                
                if (typeof value === 'string' && value.trim() === '') {
                    return true;
                }
            
                return false;
            }

            function _extractAddressField(field) {
                return userEditCtrl.user.addresses && userEditCtrl.user.addresses[0] && userEditCtrl.user.addresses[0][field] ? userEditCtrl.user.addresses[0][field].trim() : null;
            }

            function isJustDropedNotDetectedAddress(){
                // address just droped pin - not the address from previous order
                return accountInfoCtrl.isDropPinAddress && accountInfoCtrl.isDropPinAddressNotDetected;
            }

            function isShowAddressField(){
                var _isAddressFieldEnable = accountInfoCtrl.addressFormSettings.address.isEnabled;
                if(!_isAddressFieldEnable) return false;

                if(!accountInfoCtrl.isGoogleMapDropPinValidateHouseNumAndStreet) return true;

                return !isJustDropedNotDetectedAddress()
            }

            function isDisableAddressField(filteringMode){
                // only work when isAddressVerificationEnabled ON
                if(!accountInfoCtrl.isAddressVerificationEnabled) return false;
                
                // in CITY mode address field will disable when city field is empty
                if(filteringMode === FILTER_ADDRESS_MODE.CITY && accountInfoCtrl.isAddressFilteringCityMode && !_extractAddressField('city')) return true;
                
                // in ZIP_CODE mode address field will disable when city field is empty
                if(filteringMode === FILTER_ADDRESS_MODE.ZIP_CODE && accountInfoCtrl.isAddressFilteringZipCodeMode && !_extractAddressField('zipCode')) return true;

                return false;
            }

            function isShowStreetAndHouseNumberFields(){
                if(!accountInfoCtrl.isGoogleMapDropPinValidateHouseNumAndStreet) return false;

                // DROP PIN
                return isJustDropedNotDetectedAddress();
            }

            function _getInternalCities(cityQuery) {
                return AddressService.getInternalCities(cityQuery);
            }

            function _getCitiesFromGoogleMapApi(cityQuery) {
                return AddressService.getCitiesFromGoogleMapApi(cityQuery);
            }

            function getCityAutoCompleteOptions() {
                var city = _extractAddressField('city');
                if (!city) {
                    return $q.resolve([]);
                }

                //generate another promise that will return the full cities array (no only names)
                var getRequestFunc = accountInfoCtrl.isAddressVerificationEnabled ? _getCitiesFromGoogleMapApi : _getInternalCities;

                return getRequestFunc(city).then(function (data) {
                    accountInfoCtrl.suggestedCities = data.cities;
                    return data.cities;
                });
            }


            function onZipCodeChange() {
                if (!accountInfoCtrl.isAddressFilteringZipCodeMode) {
                    return;
                }
                if (accountInfoCtrl.isAddressVerificationEnabled) {
                    $scope.$emit('checkout.details.hideFormError', {
                        hiddenFields: ['zipCode'],
                        isSuggestedAddress: false
                    });
                    return;
                }
            }

            function onAutoCompleteCityChosen($event) {
                if (accountInfoCtrl.isAddressVerificationEnabled) {
                    var cityComponent = $event && $event.option && $event.option.component;

                    if ($event.option.placeId) {
                        cityComponent.placeId = $event.option.placeId;
                    }

                    if (cityComponent) {
                        angular.extend(userEditCtrl.user.addresses[0], cityComponent);
                        $scope.$emit('checkout.details.hideFormError', {
                            hiddenFields: ['city'],
                            isSuggestedAddress: false
                        });
                    }
                    onCityBlur();
                    return;
                }
            }

            function onCityChange() {
                if (accountInfoCtrl.isAddressVerificationEnabled) {
                    $scope.$emit('checkout.details.hideFormError', {
                        hiddenFields: ['city'],
                        isSuggestedAddress: false
                    });
                    return;
                }
            }

            function _isAddrSuggestionValid(placeTypes) {
                // The "text1" is the address detail
                var isInvalidPlaceType = [GOOGLE_MAP_ADDRESS_TYPES.ROUTE].some(function (type) {
                    return placeTypes.includes(type);
                });
                return !isInvalidPlaceType;
            }

            function _isExistedInSuggestions(addressType, value, suggestions) {
                var _address = userEditCtrl.user.addresses[0] || {};
                var isSuggestedAddress = _address.isSuggestedAddress || false;
                var hasCoordinatesOrPlaceId = (_address.lat && _address.lng) || _address.externalPlaceId; // Geocode includes coordinates and placeId
                if (!value || isSuggestedAddress || hasCoordinatesOrPlaceId) {
                    return true;
                }

                if(!suggestions || !suggestions.length) return false;

                for (var idx = 0; idx < suggestions.length; idx++) {
                    var item = suggestions[idx];
                    switch (addressType) {
                        case _ADDRESS_TYPE_MAP.city: {
                            if (item.mainValue && item.mainValue.toLowerCase() === value.toLowerCase()) {
                                return true;
                            }
                        }
                        case _ADDRESS_TYPE_MAP.text1: {
                            var isValid = _isAddrSuggestionValid(item.placeTypes);
                            var isExisted = item.mainValue && item.mainValue.toLowerCase() === value.toLowerCase();
                            if (isValid && isExisted) {
                                return true;
                            }
                        }
                        case _ADDRESS_TYPE_MAP.zipCode: {
                            if (item.mainValue && item.mainValue.toLowerCase().replace(/\s+/g, '') === value.toLowerCase().replace(/\s+/g, '')) {
                                return true;
                            }
                        }
                    }
                }
                return false;
            }

            function onCityBlur(event) {
                if(_isClickToSuggestion(event)) return;

                var _isCityChanged = _isLocationChanged('city');
                _handleOnLocationBlur('city');
                if (accountInfoCtrl.isAddressVerificationEnabled){
                    var city = _extractAddressField('city');
                    var isExisted = _isExistedInSuggestions(_ADDRESS_TYPE_MAP.city, city, accountInfoCtrl.suggestedCities);
                    if (!isExisted) {
                        $scope.$emit('checkout.details.setFormError', 'city');
                    }

                    if(accountInfoCtrl.isAddressFilteringCityMode && _isCityChanged){
                        accountInfoCtrl.suggestedAddresses = [];
                        userEditCtrl.user.addresses[0].text1 = '';
                        $scope.$emit('checkout.details.hideFormError', {
                            hiddenFields: ['text1'],
                            isSuggestedAddress: false
                        });
                    }
                }
            }

            function _isLocationChanged(field) {
                if (!accountInfoCtrl.backupAddress[field] || !userEditCtrl.user.addresses || !userEditCtrl.user.addresses[0] || !userEditCtrl.user.addresses[0][field]) {
                    return true;
                }
                return accountInfoCtrl.backupAddress[field].trim().toLowerCase() !== userEditCtrl.user.addresses[0][field].trim().toLowerCase();
            }

            function onAddressChosen($event) {
                var addressComponent = $event && $event.option && $event.option.component;
                if (addressComponent) {
                    return GoogleMapService.reverseGeocode({ placeId: addressComponent.externalPlaceId, languageId: $rootScope.config.language.id }).then(function (_gAddress){
                        var extractedInfo = GoogleMapService.extractInfoFromAddressComponents(_gAddress.address_components);
                        var text1Obj = GoogleMapService.constructText1(extractedInfo.countryCode, extractedInfo.houseNumber, extractedInfo.route, extractedInfo.city);

                        var _address = {
                            text1: text1Obj.value,
                            city: extractedInfo.city,
                            country: extractedInfo.country,
                            countryCode: extractedInfo.countryCode,
                            zipCode: extractedInfo.zipCode,
                            state: extractedInfo.state,
                            externalPlaceId: addressComponent.externalPlaceId,
                        }
        
                        if(accountInfoCtrl.isAddressFilteringCityMode){
                            delete _address.city;
                        } else if (accountInfoCtrl.isAddressFilteringZipCodeMode){
                            delete _address.zipCode;
                        }

                        accountInfoCtrl.isRunAddressAutoComplete = false;
                        angular.extend(userEditCtrl.user.addresses[0], _address);
                        angular.extend(accountInfoCtrl.backupAddress, _address);
                        $scope.$emit('checkout.details.hideFormError', {
                            hiddenFields: ['city', 'text1'],
                            isSuggestedAddress: false
                        });

                        onAddressBlur();
                    });
                }
            }

            function onAddressInputKeyDown() {
                if (accountInfoCtrl.isAddressVerificationEnabled) {
                    accountInfoCtrl.isRunAddressAutoComplete = true;
                }
            }

            function _handleOnLocationBlur(emittedField) {
                var isFieldChanged = _isLocationChanged(emittedField);
                if (isFieldChanged) {
                    angular.extend(userEditCtrl.user.addresses[0], {
                        externalPlaceId: null,
                        lat: null,
                        lng: null,
                    });
                    angular.extend(accountInfoCtrl.backupAddress, userEditCtrl.user.addresses[0]);
                }
                // Compare to previous address, if they are match, we will set coordinates and externalPlaceId
                var isPrevAddr = _isPreviousAddress(userEditCtrl.user.addresses[0]);
                if (isPrevAddr) {
                    var _address = accountInfoCtrl.previousFullDeliveryAddress.address;
                    var externalPlaceId = _address.externalPlaceId;
                    angular.extend(userEditCtrl.user.addresses[0], {
                        externalPlaceId: externalPlaceId,
                        lat: _address.lat,
                        lng: _address.lng,
                    });
                }
            }

            function _isPreviousAddress(currAddr) {
                var prevAddr = accountInfoCtrl.previousFullDeliveryAddress.address;
                if(!prevAddr) return false;

                return _addressComponents.every(function (field) {
                    if(!prevAddr[field]) prevAddr[field] = '';
                    if(!currAddr[field]) currAddr[field] = '';

                    return prevAddr[field].trim().toLowerCase() === currAddr[field].trim().toLowerCase();
                });
            }
            
            function _setOrInitValueForAddrField(field, value, initialValue) {
                var isDefined = angular.isDefined(userEditCtrl.user.addresses[0][field]);
                userEditCtrl.user.addresses[0][field] = isDefined ? value : initialValue;
            }

            function _getZipCodeFromGoogleMapApi(zipCode) {
                return AddressService.getZipCodeFromGoogleMapApi(zipCode, '', zipCode, true);
            }

            function onZipCodeBlur() {
                if (!accountInfoCtrl.isAddressFilteringZipCodeMode) {
                    return;
                }

                var _isZipCodeChanged = _isLocationChanged('zipCode');
                _handleOnLocationBlur('zipCode');
                
                if (accountInfoCtrl.isAddressVerificationEnabled && _isZipCodeChanged) {
                    accountInfoCtrl.suggestedAddresses = [];
                    userEditCtrl.user.addresses[0].text1 = '';
                    userEditCtrl.user.addresses[0].city = '';
                    $scope.$emit('checkout.details.hideFormError', {
                        hiddenFields: ['text1'],
                        isSuggestedAddress: false
                    });
                }
                // run it from the accountInfoCtrl to run template overrides
                if (accountInfoCtrl.isAddressVerificationEnabled) {
                    var zipCode = _extractAddressField('zipCode');
                    if (!zipCode) {
                        return $q.resolve([]);
                    }
                    //generate another promise that will return the full cities array (no only names)
                    var zipCodesDefer = $q.defer();
                    
                    return _getZipCodeFromGoogleMapApi(zipCode).then(function (data) {
                        accountInfoCtrl.suggestedZipCodes = data.zipCodes;
                        var isExisted = _isExistedInSuggestions(_ADDRESS_TYPE_MAP.zipCode, zipCode, accountInfoCtrl.suggestedZipCodes);
                        if (!isExisted) {
                            $scope.$emit('checkout.details.setFormError', 'zipCode');
                            accountInfoCtrl.isZipCodeValid = false;
                        } else {
                            $scope.$emit('checkout.details.hideFormError', {
                                hiddenFields: ['zipCode'],
                                isSuggestedAddress: false
                            }); 
                            accountInfoCtrl.isZipCodeValid = !!accountInfoCtrl.suggestedZipCodes.length;
                        }
                        zipCodesDefer.resolve(data.zipCodes);
                        return data.zipCodes;
                    }).catch(function (err) {
                        zipCodesDefer.reject(err);
                    });
                }
            }

            function _setFormError(message, isNotSave) {
                // ECOM-2174: ES5 does not have default value for params, so I use negative isNotSave
                if (message && !isNotSave) {
                    accountInfoCtrl.formErrorMessage = message;
                }
                Util.setServerErrorToForm(accountInfoCtrl.addressForm, _getFormElement(), translateFilter(message));
            }

            function _getFormElement() {
                return document.getElementsByName('accountInfoCtrl.addressForm')[0];
            }


            function getAddressAutoCompleteOptions() {
                if (!accountInfoCtrl.isRunAddressAutoComplete) {
                    return $q.resolve([]);
                }
                
                var addressQuery = _extractAddressField('text1');
                var _addressFilteringMode = accountInfoCtrl.isEnabledAddressSettings && accountInfoCtrl.enableAddressFiltering && accountInfoCtrl.addressFiltering ? accountInfoCtrl.addressFiltering : null;

                return AddressService.getAddressAutoCompleteOptions(addressQuery, {
                    addressFilteringMode:  _addressFilteringMode,
                    placeId: userEditCtrl.user.addresses[0].placeId,
                    city: userEditCtrl.user.addresses[0].city,
                    zipCode: userEditCtrl.user.addresses[0].zipCode,
                    languageId: $rootScope.config.language.id
                }).then(function (addresses) {
                    accountInfoCtrl.suggestedAddresses = addresses;
                    return addresses;
                })
            }

            function onAddressChange() {
                if (accountInfoCtrl.isAddressVerificationEnabled) {
                    $scope.$emit('checkout.details.hideFormError', {
                        hiddenFields: ['text1'],
                        isSuggestedAddress: false
                    });
                }
            }

            function onAddressBlur(event) {
                if(_isClickToSuggestion(event)) return;

                var _isAddressChanged = _isLocationChanged('text1');
                if(_isAddressChanged){
                    accountInfoCtrl.isDropPinAddress = false;
                    accountInfoCtrl.isDropPinAddressNotDetected = false;
                }

                var text1 = _extractAddressField('text1');
                if (text1) {
                    _handleWithExistingText1(text1);
                }
            }

            function _handleWithExistingText1(text1) {
                if (!accountInfoCtrl.isAddressVerificationEnabled) {
                    return;
                }
                
                _handleOnLocationBlur('text1');
                // ECOM-2174: Handling with typing address
                var city = _extractAddressField('city');
                if (city) {
                    // ECOM-2174: Validate text1
                    var address = text1 + ', ' + city;
                    var isExisted = _isExistedInSuggestions(_ADDRESS_TYPE_MAP.text1, address, accountInfoCtrl.suggestedAddresses);
                    if (!isExisted) {
                        $scope.$emit('checkout.details.setFormError', 'text1');
                    }
                } else {
                    // ECOM-2174: Auto fill city from text1
                    var suggestion = accountInfoCtrl.suggestedAddresses.find(function (item) {
                        return item.mainValue && item.mainValue.toLowerCase() === text1.toLowerCase();
                    });
                    if (suggestion) {
                        onAddressChosen({ option: suggestion });
                    } else {
                        $scope.$emit('checkout.details.setFormError', 'text1');
                    }
                }
            }

            function showText1HouseNumberWarning() {
                var _address = userEditCtrl.user.addresses[0];
                var isIgnoreValidation = [
                        _wasText1HouseNumberShown,
                        accountInfoCtrl.isGoogleMapDialogOpening,
                        !_address.text1,
                        !accountInfoCtrl.streetIsRequire,
                        _address.externalPlaceId,
                        _address.lat && _address.lng,
                    ].some(function (value) {
                        return value;
                    });

                if (isIgnoreValidation) {
                    return;
                }

                var _text1 = _address.text1;
                var splitAddress = _text1.replace(/,/g, ' ');
                splitAddress = splitAddress.replace(/\s+/g, ' ');
                splitAddress = splitAddress.split(' ');

                var hasHouseNumber = splitAddress.length >= 2 && splitAddress.some(function (word) {
                    return _houseNumberRegExp.test(word);
                });
                if (hasHouseNumber) {
                    return;
                }

                _wasText1HouseNumberShown = true;
                return Util.showCommonDialog({
                    content: '' +
                        '<strong>{{\'Please enter your house number\' | translate}}</strong>' +
                        ' <br /> ' +
                        '{{\'So we can assign you to the right branch\' | translate}}'
                });
            }

            function _verifyCityAndAddress() {
                var city = _extractAddressField('city');
                var text1 = _extractAddressField('text1');
                var zipCode = _extractAddressField('zipCode');
                var address = text1 && city ? text1 + ', ' + city : text1;
                var verifyAddressObj = {};
                if(accountInfoCtrl.isEnabledAddressSettings){
                    if(accountInfoCtrl.isDropPinAddress) return {}
                    if(accountInfoCtrl.addressFormSettings.city.isEnabled){
                        verifyAddressObj.city = _isExistedInSuggestions(_ADDRESS_TYPE_MAP.city, city, accountInfoCtrl.suggestedCities);
                    }
                    if(accountInfoCtrl.addressFormSettings.address.isEnabled){
                        verifyAddressObj.text1 = _isExistedInSuggestions(_ADDRESS_TYPE_MAP.text1, address, accountInfoCtrl.suggestedAddresses);
                    }
                    if (accountInfoCtrl.addressFormSettings.zipCode.isEnabled && accountInfoCtrl.isAddressFilteringZipCodeMode) {
                        verifyAddressObj.zipCode = _isExistedInSuggestions(_ADDRESS_TYPE_MAP.zipCode, zipCode, accountInfoCtrl.suggestedZipCodes);
                    }
                } 
                
                return verifyAddressObj;
            }

            function saveAddress(event) {
                var formElement = event.target || event.srcElement || event.path[0];
                if (accountInfoCtrl.addressForm.$invalid) {
                    return Util.setFirstErrorInput(formElement);
                }

                if (accountInfoCtrl.isAddressVerificationEnabled){
                    var validationResult = _verifyCityAndAddress();
                    for (var key in validationResult) {
                        if (!validationResult[key]) {
                            userEditCtrl.user.addresses[0][key] = '';
                            $scope.$emit('checkout.details.setFormError', key);
                            return;
                        }
                    }
                }
                
                var _isShowText1HouseNumberWarning = showText1HouseNumberWarning();
                if(_isShowText1HouseNumberWarning) return;
                
                var userAddress = angular.copy(userEditCtrl.user.addresses[0], {});

                var _isJustDropedNotDetectedAddress = isJustDropedNotDetectedAddress();
                if (_isJustDropedNotDetectedAddress && accountInfoCtrl.isGoogleMapDropPinValidateHouseNumAndStreet) {
                    var text1Obj = GoogleMapService.constructText1(userAddress.countryCode, userAddress.houseNumber, userAddress.street, userAddress.city);
                    userAddress.text1 = text1Obj.value;
                    userAddress.street = '';
                    userAddress.houseNumber = '';
                    delete userAddress.externalPlaceId;
                }

                Util.cleanUserAddressObject(userAddress);
                return Api.request({
                    method: 'PATCH',
                    url: '/retailers/:rid/users/:uid',
                    data: {
                        address: userAddress,
                    }
                }).then(function () {
                    localStorageService.removeItem('typedAreaText');
                    localStorageService.removeItem('typedAddressText');
                    localStorageService.removeItem('newAddress');
                    localStorageService.removeItem("typedAreaAddressComponents");

                    return Util.showCommonDialog({
                        title: '{{\'Successful Update\' | translate}}',
                        content: '{{\'Your account info has been successfully updated\' | translate}}.'
                    });
                }).catch(function (res) {
                    Util.setServerErrorToForm(accountInfoCtrl.addressForm, formElement, res);
                });
            }
            
            function saveUserInfo(event) {
                var formElement = event.target || event.srcElement || event.path[0];
                if (accountInfoCtrl.userInfoForm.$invalid) {
                    return Util.setFirstErrorInput(formElement);
                } else if (userEditCtrl.user.password !== userEditCtrl.user.confirmPassword) {
                    return Util.setServerErrorToForm(accountInfoCtrl.userInfoForm, formElement, {
                        response: {
                            errors: [{
                                param: 'confirmPassword',
                                msg: 'Passwords do not match'
                            }]
                        }
                    });
                }
                // can only unselect is senior citizen through here
                var isSeniorCitizen;
                if (!accountInfoCtrl.isSeniorCitizen && userEditCtrl.user.isSeniorCitizen) {
                    isSeniorCitizen = false;
                }
                
                if(userEditCtrl.user.phonesObj){
                    angular.forEach(userEditCtrl.user.phonesObj, function(phone){
                        if(userEditCtrl.editPhonesMode[phone.typeVal] && userEditCtrl.customPhoneValidation){
                            if(phone.customPhoneNumber && phone.customPhoneNumber !== '') {
                                phone.phoneNumber = [phone.areaCode ? phone.areaCode.toString() : '',
                                    phone.customPhoneNumber ? phone.customPhoneNumber.toString() : ''
                                ].join('');
                            }
                            else{
                                phone.phoneNumber = '';
                            }
                        }
                    })
                }
                return Api.request({
                    method: 'PATCH',
                    url: '/retailers/:rid/users/:uid',
                    data: {
                        general: {
                            firstName: userEditCtrl.user.firstName,
                            lastName: userEditCtrl.user.lastName,
                            birthDate: userEditCtrl.user.dateOfBirth,
                            allowSendPromotions: userEditCtrl.user.allowSendPromotions,
                            gender: parseFloat(userEditCtrl.user.gender),
                            orderComments: userEditCtrl.user.orderComments
                        },
                        password: userEditCtrl.user.password,
                        phones: userEditCtrl.user.phonesObj,
                        isSeniorCitizen: isSeniorCitizen
                    }
                }).then(function () {
                    if (isSeniorCitizen === false) {
                        userEditCtrl.user.isSeniorCitizen = isSeniorCitizen;
                    }
                    Util.checkAndRemoveErrorMessage(formElement);
                    var userPhonesActiveInputs = Object.keys(userEditCtrl.editPhonesMode);
                    if (userPhonesActiveInputs && userPhonesActiveInputs.length) {
                        userPhonesActiveInputs.forEach(function (phoneType) {
                            if(userEditCtrl.user.phonesObj && userEditCtrl.user.phonesObj[phoneType] && userEditCtrl.user.phonesObj[phoneType].phoneNumber) {
                                userEditCtrl.compileCustomValidatedPhone(userEditCtrl.user.phonesObj[phoneType])
                            }
                        })
                        userEditCtrl.setPhones();
                    }
                    return Util.showCommonDialog({
                        title: '{{\'Successful Update\' | translate}}',
                        content: '{{\'Your account info has been successfully updated\' | translate}}.'
                    });
                }).catch(function (res) {
                    Util.setServerErrorToForm(accountInfoCtrl.userInfoForm, formElement, res);
                });
            }

            function cancelPinnedAddress() {
                accountInfoCtrl.isDropPinAddress = false;
                accountInfoCtrl.isDropPinAddressNotDetected = false;
                accountInfoCtrl.droppinDisableFields.city = false;
                accountInfoCtrl.droppinDisableFields.text1 = false;
                accountInfoCtrl.droppinDisableFields.zipCode = false;
                accountInfoCtrl.droppinDisableFields.state = false;

                _setOrInitValueForAddrField('zipCode', '');
                _setOrInitValueForAddrField('text1', '');
                _setOrInitValueForAddrField('city', '');
                _setOrInitValueForAddrField('street', '');
                _setOrInitValueForAddrField('houseNumber', '');
                _setOrInitValueForAddrField('state', '');
                
                _setOrInitValueForAddrField('isSuggestedAddress', false);
            }

            function openGoogleMapDialog() {
                accountInfoCtrl.isGoogleMapDialogOpening = true;
                var _defaultLocation;
                if(accountInfoCtrl.enableAddressFiltering){
                    switch (accountInfoCtrl.addressFiltering) {
                        case FILTER_ADDRESS_MODE.ZIP_CODE:{
                            _defaultLocation = _extractAddressField('zipCode');
                            break;
                        }
                        case FILTER_ADDRESS_MODE.CITY:{
                            _defaultLocation = _extractAddressField('city');
                            break;
                        }
                    }
                }
                dialog.show({
                    templateUrl: 'template/dialogs/google-map-dialog/index.html',
                    controller: 'GoogleMapDialogCtrl as googleMapDialogCtrl',
                    locals: {
                        defaultLocation: _defaultLocation
                    }
                }).then(function (selectedAddress) {
                    if (!selectedAddress) {
                        return;
                    }

                    accountInfoCtrl.isDropPinAddress = true;
                    accountInfoCtrl.isDropPinAddressNotDetected = !selectedAddress.hasHouseNumberAndRoute;

                    accountInfoCtrl.droppinDisableFields.city = true;
                    accountInfoCtrl.droppinDisableFields.text1 = true;
                    accountInfoCtrl.droppinDisableFields.zipCode = true;
                    accountInfoCtrl.droppinDisableFields.state = true;

                    selectedAddress.externalPlaceId = null;

                    angular.extend(userEditCtrl.user.addresses[0], selectedAddress);
                    angular.extend(accountInfoCtrl.backupAddress, userEditCtrl.user.addresses[0]);

                    // If users select a valid address, we will consider it as suggested address and remove validation
                    $scope.$emit('checkout.details.hideFormError', {
                        hiddenFields: ['city', 'text1', 'zipCode'],
                        isSuggestedAddress: selectedAddress.text1 && selectedAddress.city
                    });

                    if(selectedAddress.zipCode){
                        onZipCodeBlur();
                    }
                }).finally(function () {
                    $timeout(function () {
                        /**
                         * ECOM-6403: Just temporally solution for Prutah template
                         * After closing dialog, the address input is automatically focused and blurred. This behavior affects unrecognized address behavior
                         */
                        accountInfoCtrl.isGoogleMapDialogOpening = false;
                    }, 0);
                });
            }

            function editDroppinAddressField(elementSelector, field){
                var _element = document.querySelector(elementSelector);
                if (_element) {
                    accountInfoCtrl.droppinDisableFields[field] = false;

                    $timeout(function () {
                        _element.focus();
                    }, 0);
                }
            }

            function onDroppinAddressFieldBlur(){
                accountInfoCtrl.droppinDisableFields.city = true;
                accountInfoCtrl.droppinDisableFields.text1 = true;
                accountInfoCtrl.droppinDisableFields.zipCode = true;
                accountInfoCtrl.droppinDisableFields.state = true;
            }

            function _isClickToSuggestion(event){
                // if click to choose suggestion, disable blur event temporarily
                var _parentTargetClickId = event && event.relatedTarget && event.relatedTarget.parentElement ? event.relatedTarget.parentElement.id : null;
                if (_parentTargetClickId && _parentTargetClickId.startsWith('sp_autocomplete_')) {
                    return true;
                }
                return false;
            }

            $scope.$on('checkout.details.setFormError', function (_, errorField) {
                var message;
                var addressType = _ADDRESS_TYPE_MAP[errorField];
                var addressErrorMap;
                if(accountInfoCtrl.isGoogleMapDropPinAllow){
                    message = _DEFAULT_ERROR_MESSAGE[addressType];
                    addressErrorMap = accountInfoCtrl.addressVerificationText && accountInfoCtrl.addressVerificationText[addressType];
                    if (addressErrorMap && addressErrorMap[config.language.culture]) {
                        message = addressErrorMap[config.language.culture];
                    }
                } else {
                    message = _DEFAULT_ERROR_MESSAGE_NON_DROP_PIN[addressType]
                    addressErrorMap = accountInfoCtrl.addressVerificationTextDroppinOff && accountInfoCtrl.addressVerificationTextDroppinOff[addressType];
                    if (addressErrorMap && addressErrorMap[config.language.culture]) {
                        message = addressErrorMap[config.language.culture];
                    }
                }
                accountInfoCtrl.formErrorCtrl[errorField] = true;
                _setFormError(message);
            });

            $scope.$on('checkout.details.hideFormError', function (_, emitterData) {
                if(userEditCtrl.user.addresses && userEditCtrl.user.addresses[0]){
                    userEditCtrl.user.addresses[0].isSuggestedAddress = emitterData.isSuggestedAddress || false;
                }
                angular.forEach(emitterData.hiddenFields, function (field) {
                    accountInfoCtrl.formErrorCtrl[field] = false;
                });
                
                var _errorKey;
                if(accountInfoCtrl.formErrorCtrl.text1) { _errorKey = 'text1'; }
                else if(accountInfoCtrl.formErrorCtrl.city) { _errorKey = 'city'; }
                else if(accountInfoCtrl.formErrorCtrl.zipCode) { _errorKey = 'zipCode'; }

                if(_errorKey){
                    $scope.$emit('checkout.details.setFormError', _errorKey);
                    return;
                }

                _setFormError('');
            });
        }
    ]);
})(angular, app);
