(function (angular, app) {
  'use strict'

  app.service('FormCompletionDialog', [
    'Dialog',
    function (Dialog) {
      this.show = show
      this.hide = hide

      function show(data) {
        return Dialog.show({
          templateUrl: 'template/dialogs/login-or-register-v2/completion/index.html',
          controller: 'FormCompletionCtrl as formCompletionCtrl',
          ariaLabelledby: 'form_completion_dialog_title',
          styleClass: 'form-completion-dialog',
          disableClosing: true,
          locals: {
            data: data
          }
        })
      }

      function hide() {
        return Dialog.hide()
      }
    }
  ])

  app.run([
    '$rootScope',
    'FormCompletionDialog',
    function ($rootScope, FormCompletionDialog) {
      $rootScope.FormCompletionDialog = FormCompletionDialog
    }
  ])

  app.controller('FormCompletionCtrl', [
    '$rootScope',
    '$filter',
    '$window',
    'User',
    'Api',
    'SpDialogUrlManager',
    'DataLayer',
    'Util',
    'Cart',
    'Toast',
    'SpCaptcha',
    'data',
    function (
      $rootScope,
      $filter,
      $window,
      User,
      Api,
      SpDialogUrlManager,
      DataLayer,
      Util,
      Cart,
      Toast,
      SpCaptcha,
      data
    ) {
      var formCompletionCtrl = this
      var _translate = $filter('translate')

      angular.extend(formCompletionCtrl, {
        LoginWithOtpOptions: $rootScope.LOGIN_WITH_OTP_OPTIONS,
        UserTypeForCompletionForm: $rootScope.USER_TYPE_FOR_COMPLETION_FORM,

        captchaIsInvalid: false,
        captchaMessage: '',
        completionFormError: '',
        toggleCloseConfirmation: false,
        user: data.user,
        selectedOption: null,

        type: data.type,
        phoneNumber: data.phoneNumber,
        byLaws: data.byLaws,
        shouldDisableEmailField: data.shouldDisableEmailField,
        shouldDisablePhoneNumberField: data.shouldDisablePhoneNumberField,
        completionFormFields: data.completionFormFields,
        checkboxes: data.checkboxes,
        completionFormTitle: data.completionFormTitle,
        completionFormSubtitle: data.completionFormSubtitle,
        completionFormBtxText: data.completionFormBtxText,

        closeDialogAndLogout: closeDialogAndLogout,
        goBack: goBack,
        complete: complete,
        onSelect: onSelect,
        disabledFields: []
      })

      _init()

      function _init(setFocus) {
        if(data.disabledFields){
          formCompletionCtrl.disabledFields = data.disabledFields;
        }
        else{
          formCompletionCtrl.disabledFields = [];
        }
        return User.getData(true).then(function (userData) {
          formCompletionCtrl.userData = userData

          if(!formCompletionCtrl.phoneNumber) {
            formCompletionCtrl.phoneNumber = formCompletionCtrl.userData.phoneNumber
          }

          if (setFocus) {
            _setFocusOnBody()
          }

          if(formCompletionCtrl.phoneNumber || formCompletionCtrl.user.phoneNumber) {
            return complete()
          }
        })
      }

      function _getEventFormElement(event) {
        var element = event && (event.target || event.srcElement || event.path[0]);

        while (element && element.tagName.toUpperCase() !== 'FORM') {
          element = element.parentElement || element.parentNode
        }

        return element
      }

      function validateForm(form, event) {
        var formElement = _getEventFormElement(event)
        var isNewSiteMem = [
          formCompletionCtrl.UserTypeForCompletionForm.NEW_USER,
          formCompletionCtrl.UserTypeForCompletionForm.LOYALTY_MEMBER_BUT_NOT_SITE_MEMBER,
        ].includes(formCompletionCtrl.type);

        if(formElement) Util.setServerErrorToForm(form, formElement, '');

        if (form.$invalid) {
          Util.setFirstErrorInput(formElement)
          angular.forEach(form.$error.required, function (field) {
            if (field.$$attr.id === 'captcha_hidden_input') {
              formCompletionCtrl.captchaIsInvalid = true
              formCompletionCtrl.captchaMessage = _translate('Verification expired. Check the checkbox again')
            }
          })
          return false
        } else if (formCompletionCtrl.user.password != formCompletionCtrl.user.confirmPassword && isNewSiteMem) {
          Util.setServerErrorToForm(form, formElement, {
            response: {
              errors: [
                {
                  param: 'confirmPassword',
                  msg: 'Passwords do not match'
                }
              ]
            }
          })
          return false
        }

        if(isNewSiteMem) {
          if (!formCompletionCtrl.user.policyApproval) {
            Util.setServerErrorToForm(form, formElement, {
              response: {
                error: _translate('policyapproval: required error')
              }
            })
            return false
          } 

          if(formCompletionCtrl.type === formCompletionCtrl.UserTypeForCompletionForm.NEW_USER) {
            if (!formCompletionCtrl.user.byLaws) {
              Util.setServerErrorToForm(form, formElement, {
                  response: {
                      error: $filter('translate')('loyaltypolicyapproval: required error')
                  }
              });
              return false;
            }
          }
        }

        if(formCompletionCtrl.type === formCompletionCtrl.UserTypeForCompletionForm.SITE_USER_BUT_NOT_LOYALTY_MEMBER) {
          if (!formCompletionCtrl.user.byLaws) {
            Util.setServerErrorToForm(form, formElement, {
                response: {
                    error: $filter('translate')('loyaltypolicyapproval: required error')
                }
            });
            return false;
          }
        }

        return true
      }

      function register(form, event) {
        formCompletionCtrl.showSuccessMessage = false;

        if (form && !validateForm(form, event)) return

        DataLayer.push(DataLayer.EVENTS.SELECT_CONTENT, {
          data: { category: 'Button', action: 'Click', label: 'Register to site' }
        })

        var invitePromotion = {
          inviteId: Number(localStorage.getItem('inviteId')),
          promotionId: Number(localStorage.getItem('promotionId'))
        }

        if (invitePromotion.inviteId && invitePromotion.promotionId) {
          formCompletionCtrl.user.invitePromotion = invitePromotion
        }

        formCompletionCtrl.user.cartId = Cart.serverCartId || undefined

        if ($rootScope.externalUserCode) {
          formCompletionCtrl.user.externalUserCode = $rootScope.externalUserCode
        }

        var recaptchaHash = SpCaptcha.getLastCaptchaVerifyHash()
        formCompletionCtrl.user.recaptchaHash = recaptchaHash
        $rootScope.emailForReActivation = formCompletionCtrl.user.email
        $rootScope.registrationInProcess = true
        Api.request({
          method: 'POST',
          url: '/retailers/:rid/users',
          data: formCompletionCtrl.user
        })
          .then(function (registerData) {
            $rootScope.registrationInProcess = true
            if (invitePromotion.inviteId || invitePromotion.promotionId) {
              localStorage.removeItem('inviteId')
              localStorage.removeItem('promotionId')
            }

            DataLayer.push(DataLayer.EVENTS.SELECT_CONTENT, {
              data: { category: 'Button', action: 'Click', label: 'Register successful' }
            })

            Toast.show({
              timeout: 5000,
              content: "{{'Thank you, Registration completed successfully'| translate}}"
            })

            return User.login(formCompletionCtrl.user.email, formCompletionCtrl.user.password).then(function () {
              $rootScope.$emit('customerRegistration', {
                id: registerData.userId,
                firstName: formCompletionCtrl.user.firstName,
                lastName: formCompletionCtrl.user.lastName,
                email: formCompletionCtrl.user.email,
                allowSendPromotions: formCompletionCtrl.user.allowSendPromotions
              })

              localStorage.setItem('isFirstUserLogin', true)
              return registerData
            }).then(function() {
              return User.getData().then(function() {
                closeDialog();
                Util.reload(true);
              })
            })
          })
          .then(function (registerData) {
            if (registerData.cartId) {
              // wait for after the login, for the cart call to have a token
              Cart.replaceCart(registerData.cartId, true)
            }
          })
          .then(function () {
            DataLayer.push(DataLayer.EVENTS.SIGN_UP)
          })
          .then(function () {
            // not the most beautiful, continuing openLoyaltyAfterRegister
            $window.hideAfterRegister = true
            $rootScope.registrationInProcess = false
            $rootScope.$apply()
          })
          .catch(function (err) {
            $rootScope.registrationInProcess = false;

            DataLayer.push(DataLayer.EVENTS.ERROR_SIGN_UP, {
              data: { email: formCompletionCtrl.user.email, error: err }
            })

            if(err && err.response && err.response.code === 'registrationAccountNotActivated') {
              formCompletionCtrl.showSuccessMessage = true;
            } else {
              var formElement = _getEventFormElement(event);
              if(formElement) Util.setServerErrorToForm(form, formElement, err);
            }

            formCompletionCtrl.showHubPolicy = false;
            $rootScope.$apply()
          })
      }

      function closeDialog() {
        return $rootScope.FormCompletionDialog.hide()
      }

      function goBack() {
        if(formCompletionCtrl.userData) {
          formCompletionCtrl.toggleCloseConfirmation = true
          return;
        }
        closeDialog()
        return Util.openLoginDialog()
      }

      function closeDialogAndLogout() {
        if(formCompletionCtrl.userData) User.logout()
        return $rootScope.FormCompletionDialog.hide()
      }

      function complete(form, event) {
        var isNewUserForm = [
          formCompletionCtrl.UserTypeForCompletionForm.NEW_USER,
          formCompletionCtrl.UserTypeForCompletionForm.LOYALTY_MEMBER_BUT_NOT_SITE_MEMBER,
        ].includes(formCompletionCtrl.type);

          if (isNewUserForm)
              return register(form, event);
          else if (formCompletionCtrl.type === formCompletionCtrl.UserTypeForCompletionForm.SITE_USER_BUT_NOT_LOYALTY_MEMBER) {
            if(!validateForm(form, event)) return;

            if (formCompletionCtrl.userData) {
              formCompletionCtrl.user.firstName = formCompletionCtrl.userData.firstName
              formCompletionCtrl.user.lastName = formCompletionCtrl.userData.lastName
              
              formCompletionCtrl.user.phoneNum = formCompletionCtrl.phoneNumber

              return Api.request({
                method: 'POST',
                url: '/v2/retailers/:rid/users/:uid/loyalty-clubs/_extended-registration',
                data: formCompletionCtrl.user
              }).then(function () {
                return closeDialog()
              })
            }
          }
      }

      function onSelect(model, opt) {
        formCompletionCtrl.user[model] = opt.value
        formCompletionCtrl.selectedOption = opt
      }

      /**
       * Accessibility support
       *
       * @private
       */
      function _setFocusOnBody() {
        var dialogBody = document.querySelector('.dialog-wrapper .dialog > .dialog-body')
        dialogBody && dialogBody.focus()
      }
    }
  ])
})(angular, app)
