(function (angular, app) {
    'use strict';

    app.directive('productActions', ['Util', function (util) {
        return {
            restrict: 'E',
            templateUrl: 'template/directives/product-actions/index.html',
            replace: true,
            scope: {
                mode: '@', // landscape/portrait
                product: '=',
                line: '=?',
                isCase: '=?',
                isPack: '=?',
                removeFromCart: '=',
                text: '@',  // only in landscape mode
                hideCart: '@',
                estText: '@',
                productPropertyValue: '=?',
                hideAddButton: '=?',
                showError: '=',
                openProduct: '&?',
                spAriaLive: '=?',
                onClick: '&?',
                showReplacementLabel: '=?',
                source: '@',
                index: '@',
                itemPosition: '=',
                componentType: '=',
                componentPosition: '=',
            },
            controller: ['$scope', '$element', '$timeout', 'Cart', 'User', '$rootScope', 'Dialog', 'Util', function ($scope, $element, $timeout, cart, user, $rootScope, dialog, Util) {
                $scope.getLine = function () {
                    return $scope.line || util.getProductCartLine($scope.product, $scope.isCase);
                };

                $scope.setFocus = function () {
                    $timeout(function () {
                        var focusElement = $element[0].querySelector('.sp-quantity button') || $element.parent()[0];
                        focusElement && focusElement.focus();
                    });
                };

                // $scope.replace = function (line, product, index) {
                //     if ($scope.hideAddButton) {
                //         $scope.openProduct(product, index);
                //     } else {
                //         $scope.addLine(line);
                //     }
                //     var line = util.getProductCartLine(product.oosProduct, $scope.isCase);
                //     console.log('ADD / REMOVE....');
                //     cart.removeLine(line, true);
                //     // console.log('DOING REPLACE ACTION....');
                //     // $rootScope.$emit('cart.product.replace', {
                //     //     product: product
                //     // })
                // };

                $scope.removeOOS = function(product) {
                    if (product.oosProduct) {
                        user.updateReplacementSuggestions([{
                            id: product.oosProduct.id,
                            suggestions: [{
                                id: product.id,
                                approved: true
                            }]
                        }]);

                        var line;
                        angular.forEach(cart.getLines(), function(cartLine) {
                            if (cartLine.product.id === product.oosProduct.id) {
                                line = cartLine;
                            }
                        });

                        if (!line) {
                            line = util.getProductCartLine(product.oosProduct, $scope.isCase);
                        }

                        if (!line) {
                            return;
                        }

                        $rootScope.cart.removeLine(line, true).then(function() {
                            if (product.oosProduct._suggestions && product.oosProduct._suggestions.length) {
                                angular.forEach(product.oosProduct._suggestions, function(product) {
                                    delete product.oosProduct;
                                });
                            }
                            // delete product.oosProduct;
                            !$rootScope.$$phase && $rootScope.$apply();

                            // support replace action for line with hide add button
                            if ((!$scope.getLine() || !$scope.getLine().quantity) && $scope.hideAddButton) {
                                dialog.hide({isConfirmed: false}).then(function() {
                                    util.openProduct(product, {source: 'Replacements Pop Up'});
                                });
                            }
                            // if ($scope.onClick) {
                            //     $scope.onClick();
                            // }
                        })
                    }
                }

                $scope.addLine = function (line) {
                    if ($scope.onClick) {
                        $scope.onClick();
                    }
                    if (line && line.product && line.product.productProperties && line.product.productProperties.length && !line.productPropertyValue && $scope.showError !== undefined) {
                        $scope.showError = true;
                        return;
                    }
                    var isLimitPassed = cart.checkHeavyLimitBeforeAddLine(line);
                    if (isLimitPassed === false) {
                        if (line.productPropertyValue && line.product.productProperties) {
                            line.productPropertyValue.propertyNames = line.product.productProperties[0].names;
                        }
                        if (cart.lines && Object.keys(cart.lines).length === 0 &&
                            $rootScope.config.retailer.settings.includeDeliveryFeeInCart === 'true') {
                                cart.addDeliveryFeeLineIfNeeded();
                        }

                        if (line.product.oosProduct) {
                            var oosLine = util.getProductCartLine(line.product.oosProduct, $scope.isCase);
                            line.quantity = oosLine ? Math.ceil(oosLine.oldQuantity) : undefined;
                        }
                        if ($scope.source) {
                            var sourceName = Util.extractHomeCarouselName($scope.source, $scope.componentType, $scope.componentPosition);
                            $scope.source = sourceName
                        }
                        cart.addLine(line, line.product && line.product.soldBy, {source: $scope.source || line.source, index: $scope.itemPosition || line.index});
                    }
                }
            }]
        };
    }]);
})(angular, app);