(function (angular, app) {
    'use strict';

    app.controller('ProductSuggestionsCtrl', [
        '$rootScope', '$scope', 'Dialog', 'Util', 'Cart', 'product',
        function ($rootScope, $scope, Dialog, util, cart, product) {
            var productSuggestionsCtrl = this,
                _isInitCarousel = false;

            product.showOutOfStock = true;
            productSuggestionsCtrl.product = product;
            productSuggestionsCtrl.products = [product];
            productSuggestionsCtrl.productsTotal = 1;
            productSuggestionsCtrl.eventId = 'init.carousel';

            _initSuggestions();

            // todo use back to cart
            productSuggestionsCtrl.backToCart = function () {
                Dialog.hide({isConfirmed: false});
            };

            productSuggestionsCtrl.productClick = function (event) {
                var product = event[0];
                if (event.item) {
                    product = event.item;
                }

                Dialog.hide({isConfirmed: false}).then(function() {
                    util.openProduct(angular.isArray(product) ? product[0] : product, {source: 'Replacements Pop Up'});
                });
            }

            // util.currentScopeListener($scope, $rootScope.$on('cart.update.complete', function () {
            //     _initSuggestions();
            // }));

            function _initSuggestions() {
                product.isOOS = true;
                angular.forEach(product._suggestions, function(suggestion) {
                    suggestion = cart.getProduct(suggestion);
                    suggestion.oosProduct = product;
                });

                !$rootScope.$$phase && $scope.$apply();

                if (_isInitCarousel) {
                    return;
                }

                _isInitCarousel = true;

                setTimeout(function() {
                    $rootScope.$emit(productSuggestionsCtrl.eventId);
                    _isInitCarousel = false;
                }, 1000);
            }
        }]);
})(angular, app);